import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { withStyles, Fab, Avatar, Tooltip } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";

import AbaRDMentorIa from "../AbaRDMentorIA";
import Icone from "../../../_common/icones/Logo_Redondo_RD_Station.svg";
import { useUsuario } from "../../../hooks";
import { FreshChatWidget, InitFreshChat, DestroyChat } from "../FreshChatWidget/FreshChatWidget";

function FloatingButton({ classes }) {
  const intl = useIntl();
  const [abrirMentorIA, setAbrirMentorIA] = useState(false);
  const { nome, email } = useUsuario();
  const scriptAgenteMWI = useRef(null);

  useEffect(() => {
    scriptAgenteMWI.current = document.getElementById("mw-widget-frame");
    if (scriptAgenteMWI.current === null) {
      if (window.fcWidget) {
        DestroyChat();
        setTimeout(() => {
          InitFreshChat(
            ["v2"],
            {
              headerProperty: {
                hideChatButton: true,
              },
            },
            nome,
            email
          );
        }, 1500);
      } else {
        FreshChatWidget(
          ["v2"],
          {
            headerProperty: {
              hideChatButton: true,
            },
          },
          nome,
          email
        );
      }
    }
    return DestroyChat();
  }, []);

  const abrirSuporte = () => {
    window.fcWidget.open();
  };

  return (
    scriptAgenteMWI.current == null && (
      <>
        <>
          <Tooltip
            placement="left"
            title={intl.formatMessage({ defaultMessage: "Suporte" })}
            classes={{ tooltip: classes.lightTooltip }}
          >
            <Fab
              aria-label="Suporte"
              size="small"
              className={classes.fabMessage}
              id="float-button-suporte"
              onClick={abrirSuporte}
            >
              <ChatIcon color="primary" />
            </Fab>
          </Tooltip>
          <Tooltip
            placement="left"
            title={intl.formatMessage({ defaultMessage: "Mentor IA" })}
            classes={{ tooltip: classes.lightTooltip }}
          >
            <Fab
              aria-label="MagicWrite"
              size="small"
              className={classes.fabMagic}
              id="float-button-mentorIA"
              onClick={() => setAbrirMentorIA(true)}
            >
              <Avatar alt="Remy Sharp" src={Icone} className={classes.avatar} />
            </Fab>
          </Tooltip>
        </>
        <AbaRDMentorIa aberto={abrirMentorIA} estadoAba={setAbrirMentorIA} />
      </>
    )
  );
}

export default withStyles(theme => ({
  fab: {
    position: "fixed",
    zIndex: 99,
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  fabMessage: {
    position: "fixed",
    zIndex: 99,
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3,
    animation: "fadeIn 1s",
  },
  fabMagic: {
    position: "fixed",
    zIndex: 99,
    bottom: theme.spacing.unit * 9,
    right: theme.spacing.unit * 3,
    animation: "fadeIn 1s",
    backgroundColor: "#27bec9",
  },
  contentFreshDesk: {
    position: "fixed",
    bottom: theme.spacing.unit * 11,
    right: theme.spacing.unit * 3,
    width: 350,
    height: 450,
  },
  fecharFreshDesk: {
    position: "absolute",
    top: theme.spacing.unit,
    right: theme.spacing.unit,
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.textColor,
    boxShadow: theme.shadows[1],
  },
}))(FloatingButton);
