import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { ListItem } from "material-ui";
import { Link } from "react-router-dom";
import { estadoMenu } from "../constantes";

MenuAccordion.propTypes = {
  open: PropTypes.bool,
  menuState: PropTypes.string,
  item: PropTypes.object,
  getIconComponent: PropTypes.func,
  handleEstadoAcordions: PropTypes.func,
};

const ListItemIconStyled = styled(ListItemIcon)(() => ({
  minWidth: 0,
  justifyContent: "center",
  color: "#C4C8CC",
  marginRight: "10px",
}));

const AccordionStyled = styled(Accordion)(() => ({
  backgroundColor: "transparent",
  "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded, .MuiAccordionSummary-root.Mui-expanded": {
    margin: 0,
    minHeight: 48,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#C4C8CC",
  },
  "& .MuiAccordionDetails-root": {
    padding: 0,
  },
  "& .MuiAccordionDetails-root > div > span > div > div": {
    marginLeft: "0px !important",
    padding: "0px !important",
    paddingLeft: "60px !important",
    position: "relative",
  },
  "& .MuiTypography-root": {
    fontSize: 14,
  },
  "& .MuiAccordionSummary-root": {
    paddingLeft: 15,
    paddingRight: 16,
  },
}));

function MenuAccordion({ open, item, getIconComponent, handleEstadoAcordions, menuState }) {
  const [expanded, setExpanded] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = index => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  useEffect(
    () => {
      if (menuState === estadoMenu.FECHADO) {
        setExpanded(() => false);
      }
    },
    [menuState]
  );

  useEffect(
    () => {
      if (expanded === true) {
        handleEstadoAcordions(estadoMenu.ABERTO);
      }
    },
    [expanded]
  );

  const handleAccordionClick = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };
  return (
    <AccordionStyled
      expanded={expanded}
      onClick={() => handleAccordionClick()}
      elevation={0}
      square
      slots={{ transition: Collapse }}
      slotProps={{ transition: { duration: 0.5, easing: "ease" } }}
      sx={{ width: open ? "100%" : "auto" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          minHeight: 48,
          justifyContent: "initial",
          px: 2.5,
          "&:hover": {
            textDecoration: "none",
            "& .MuiListItemText-primary": { color: "#fff" },
            background: "rgba(0, 0, 0, 0.5) !important",
            borderColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <ListItemButton
          id={item?.idsInline?.idItem}
          sx={{ minHeight: 48, justifyContent: "initial", padding: "8px 0" }}
        >
          <ListItemIconStyled id={item?.idsInline?.idIcon}>{getIconComponent(item.icon)}</ListItemIconStyled>
          <ListItemText primary={item.title} sx={{ opacity: 1, color: "#C4C8CC" }} />
        </ListItemButton>
      </AccordionSummary>
      <AccordionDetails>
        {item.accordionItems.map((element, index) => (
          <ListItem
            key={element.title}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            style={
              hoveredItem === index
                ? {
                    textDecoration: "none",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderColor: "rgba(0, 0, 0, 0.5)",
                  }
                : {}
            }
          >
            <ListItemButton
              id={element?.idsInline?.idItem}
              component={Link}
              to={element.url}
              sx={{
                minHeight: 48,
                justifyContent: "initial",
                padding: "8px 0",
                "&:hover": {
                  "& .MuiListItemText-primary": { color: "#fff" },
                },
              }}
              onClick={() => {
                handleEstadoAcordions(estadoMenu.FECHADO);
              }}
            >
              {element.icon && <ListItemIconStyled>{getIconComponent(element.icon)}</ListItemIconStyled>}
              <ListItemText primary={element.title} sx={{ opacity: 1, color: "#C4C8CC" }} />
            </ListItemButton>
          </ListItem>
        ))}
      </AccordionDetails>
    </AccordionStyled>
  );
}

export default MenuAccordion;
