import React from "react";
import PropTypes from "prop-types";
import { Paper, withStyles, Grid } from "@material-ui/core";
import cn from "classnames";

import FilterTextField from "./FilterTextField";

FilterText.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCancelSearch: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputGridItemProps: PropTypes.object,
  /** Preferencialmente utilizar Grid items */
  children: PropTypes.node,
  classes: PropTypes.object,
};

FilterText.defaultProps = {
  disabled: false,
  inputGridItemProps: {
    xs: 8,
    md: 6,
    lg: 4,
    xl: 3,
  },
};

function FilterText({
  className,
  value,
  onChange,
  placeholder,
  onCancelSearch,
  disabled,
  inputGridItemProps,
  children,
  classes,
}) {
  return (
    <Paper className={cn(classes.root, className)}>
      <Grid container spacing={16} alignItems="flex-end">
        <Grid item {...inputGridItemProps}>
          <FilterTextField
            id="filter-text-input"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onCancelSearch={onCancelSearch}
            disabled={disabled}
          />
        </Grid>
        {children}
      </Grid>
    </Paper>
  );
}

export default withStyles(theme => ({
  root: {
    margin: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3,
  },
}))(FilterText);
