import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import MensagemCliente from "./MensagemCliente";
import MensagemSolicitacaoCancelada from "./MensagemSolicitacaoCancelada";
import env from "../../src/../../../environment";

function SolicitaAlteracaoPlano() {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");
  const baseUrl = env.REACT_APP_BASE_URL;
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${baseUrl}/Api/Pipeline/FuncionalidadePlano/SolicitaAlteracao?code=${code}`);
      setData(response.data);
    }
    fetchData();
  }, []);

  return <>{data === 0 ? <MensagemSolicitacaoCancelada /> : <MensagemCliente />}</>;
}

export default SolicitaAlteracaoPlano;
