import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "react-jss";
import { compose } from "recompose";
import { FontIcon } from "material-ui";

import { Flex, Tipografia } from "../..";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = theme => ({
  container: {
    width: "100%",
    margin: [24, 0],
  },
  divIcone: {
    height: 168,
    width: 168,
    backgroundColor: theme.palette.borderColor,
    borderRadius: "50%",
    marginBottom: 16,
  },
  divBotoes: {
    marginTop: 16,
    width: 300,
    justifyContent: "space-around",
  },
  mensagemSemRegistro: {
    whiteSpace: "pre-line",
    textAlign: "center",
  },
});

const inlineStyles = {
  iconStyle: {
    fontSize: 72,
  },
};

const ListagemSemRegistro = ({ icone, mensagem, botoes, theme, classes }) => (
  <Flex alignItems="center" flexDirection="column" className={classes.container}>
    <Flex center className={classes.divIcone}>
      <FontIcon className="material-icons" style={inlineStyles.iconStyle} color={theme.palette.icons.darkInactiveIcon}>
        {icone}
      </FontIcon>
    </Flex>

    <Tipografia tipo="navbar" cor="darkSecondaryText" className={classes.mensagemSemRegistro}>
      {mensagem}
    </Tipografia>

    {botoes && (
      <Flex className={classes.divBotoes}>
        <div>{botoes}</div>
      </Flex>
    )}
  </Flex>
);

ListagemSemRegistro.propTypes = {
  /** material-icons */
  icone: PropTypes.string.isRequired,
  mensagem: PropTypes.node.isRequired,
  /** Botões para serem mostrados abaixo da mensagem */
  botoes: PropTypes.node,
  theme: PropTypes.object,
  /** @ignore */
  classes: PropTypes.object,
};

const enhance = compose(
  withTheme,
  injectSheet(styles)
);

export { ListagemSemRegistro as ListagemSemRegistroPure };

export default enhance(ListagemSemRegistro);
