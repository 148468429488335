import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import injectSheet from "../../hocs/injectSheet/injectSheet";
import Tipografia from "../Tipografia/Tipografia";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "baseline",
    marginTop: 2,
  },
  breadcrumb: {
    color: theme.palette.secondaryTextColor,
  },
  breadcrumbAtivo: {
    color: theme.palette.textColor,
  },
  svgArrow: {
    marginBottom: -6,
    height: "20px !important",
    width: "20px !important",
    color: `${theme.palette.secondaryTextColor} !important`,
  },
  link: {
    textDecoration: "none",
  },
});

const Breadcrumb = ({ classes, valores, className }) => (
  <div className={classNames(classes.root, className)}>
    {valores.map((elem, index) => {
      const isUltimoBreadcrumb = index === valores.length - 1;
      return (
        <div key={elem.titulo}>
          <Tipografia
            url={elem.url}
            urlExterna={elem.urlExterna}
            className={classNames(classes.breadcrumb, {
              [classes.breadcrumbAtivo]: isUltimoBreadcrumb,
              [classes.link]: elem.url,
            })}
            tipo="notaLegendas"
            cor="darkPrimaryText"
          >
            {elem.titulo}
          </Tipografia>
          {!isUltimoBreadcrumb && <KeyboardArrowRight className={classes.svgArrow} />}
        </div>
      );
    })}
  </div>
);

Breadcrumb.propTypes = {
  /** Array de breadcrumbs para serem renderizados */
  valores: PropTypes.arrayOf(
    PropTypes.shape({
      /** Título do breadcrumb */
      titulo: PropTypes.string.isRequired,
      /** Url do breadcrumb */
      url: PropTypes.string,
      /** Indica se a url é externa (`httm://google.com`) ou interna (url do front do react) */
      urlExterna: PropTypes.bool,
    })
  ).isRequired,
  /** @ignore */
  classes: PropTypes.object,
  /** Classname a ser aplicado no root da aplicação */
  className: PropTypes.string,
};

Breadcrumb.defaultProps = {
  className: null,
};

export default injectSheet(styles)(Breadcrumb);
