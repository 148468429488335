import React, { memo } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import PropTypes from "prop-types";
import routes from "../MainRoutes/constantes";
import { usePermission, usePerfilPermission, useRolloutPermission, useCustomPermission } from "../../../hooks";

const ExactRoute = ({
  component: Component,
  usuario,
  roles = [],
  claims = [],
  featureToggles = [],
  customPermission = "",
  ...rest
}) => {
  const usuarioLogado = usuario.isUsuarioLogado;

  const checkPermissaoPerfil = usePerfilPermission(roles);
  const checkPermissaoClaims = usePermission(claims, null, "OR", true) || claims.length === 0 || claims === null;
  const checkPermissaoRollouts = useRolloutPermission(featureToggles);
  const checkCustomPermission = useCustomPermission(customPermission);

  const routeDnD =
    rest.path === routes.CONFIGURACOES_FILTROS_QUESTIONARIOID.route ||
    rest.path === routes.DASHBOARD.route ||
    rest.path === routes.DASHBOARD_CONFIGURAR.route;
  const propsRoute = Component ? { render: props => <Component {...props} /> } : { ...rest };
  if (!usuarioLogado) return null;

  if (customPermission && !checkCustomPermission) {
    return <Redirect to="/spotter/acesso-restrito/sem-permissao-acesso" />;
  }

  if (checkPermissaoPerfil && checkPermissaoClaims && checkPermissaoRollouts) {
    if (routeDnD)
      return (
        <DndProvider backend={HTML5Backend}>
          <Route {...rest} {...propsRoute} />
        </DndProvider>
      );
    return <Route {...rest} {...propsRoute} />;
  }

  return <Redirect to="/spotter/acesso-restrito/sem-permissao-acesso" />;
};

ExactRoute.propTypes = {
  roles: PropTypes.array,
  claims: PropTypes.array,
  featureToggles: PropTypes.array,
  customPermission: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  usuario: PropTypes.object,
};

const mapStateToProps = state => ({ usuario: state.usuario });

export default connect(mapStateToProps)(memo(ExactRoute));
