import React from "react";
import PropTypes from "prop-types";
import { TextField, withStyles, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useIntl } from "react-intl";

FilterTextField.propTypes = {
  value: PropTypes.string,
  onCancelSearch: PropTypes.func,
};

function FilterTextField({ onCancelSearch, classes, ...props }) {
  const intl = useIntl();
  return (
    <TextField
      className={classes.textField}
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} color="disabled" />
          </InputAdornment>
        ),
        endAdornment:
          props.value && typeof onCancelSearch === "function" ? (
            <InputAdornment position="end" title={intl.formatMessage({ defaultMessage: "Limpar" })}>
              <CloseIcon className={classes.cancelIcon} color="action" onClick={onCancelSearch} fontSize="small" />
            </InputAdornment>
          ) : null,
      }}
    />
  );
}

export default withStyles(theme => ({
  searchIcon: {
    color: theme.palette.icons.darkInactiveIcon,
  },
  textField: {
    width: "100%",
  },
  cancelIcon: {
    position: "absolute",
    right: 0,
    top: 6,
    background: theme.palette.canvasColor,
    color: theme.palette.icons.darkActiveIcon,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.icons.darkFocusedIcon,
    },
  },
}))(FilterTextField);
