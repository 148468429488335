import React from "react";
import PropTypes from "prop-types";
import { Popover, Menu, MenuItem, Avatar } from "material-ui";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Flex, Tipografia, Divider } from "../..";
import injectSheet from "../../hocs/injectSheet/injectSheet";
import { useTipoPlanoSpotter, useUsuario } from "../../../hooks";
import { deslogar } from "../../utils/auth/auth";
import { IdsInlineManual } from "../../utils/constants/inlineManual";

const styles = {
  menuUsuario: {
    height: 57,
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 16,
    minWidth: 154,
  },
  menuInfosUsuario: {
    width: 320,
    height: 100,
  },
  infosUsuario: {
    paddingLeft: 8,
    height: 48,
  },
  divNomeCargo: { paddingLeft: 8 },
  menuDesktop: {
    textAlign: "center",
  },
  divider: {
    width: "100%",
    marginBottom: 16,
  },
};

const MenuInformacoesConta = ({
  usuario,
  menuUsuarioOpen,
  anchorElMenuUsuario,
  handleClickMenuUsuario,
  handleRequestCloseMenuUsuario,
  linkSuaConta,
  linkCentralAjuda,
  linkPluginLinkedin,
  linkLogOff,
  classes,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { featureToggles } = useUsuario();
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <React.Fragment>
      <Flex onClick={handleClickMenuUsuario} className={classes.menuUsuario}>
        {usuario.urlLogoEmpresa && <Avatar src={usuario.urlLogoEmpresa} size={32} />}
        <Flex flexDirection="column" id={IdsInlineManual.NAVBAR_USUARIO_NOME} className={classes.divNomeCargo}>
          <Tipografia id="idNomeUsuarioLogado" cor="lightSecondaryText" tipo="botoes">
            {usuario.nome}
          </Tipografia>
          <Tipografia id="idCargoUsuarioLogado" cor="lightSecondaryText" tipo="notaLegendas">
            {usuario.cargo}
          </Tipografia>
        </Flex>
      </Flex>

      <Popover
        open={menuUsuarioOpen}
        anchorEl={anchorElMenuUsuario}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        targetOrigin={{ horizontal: "right", vertical: "top" }}
        onRequestClose={handleRequestCloseMenuUsuario}
      >
        <Flex center className={classes.menuInfosUsuario}>
          {usuario.urlLogoEmpresa && <Avatar src={usuario.urlLogoEmpresa} size={57} />}
          <Flex flexDirection="column" justifyContent="space-between" className={classes.infosUsuario}>
            <Tipografia id="idNomeUsuarioLogadoInterno" cor="darkSecondaryText" tipo="botoes">
              {usuario.nome}
            </Tipografia>
            <Tipografia id="idNomeCargoLogadoInterno" cor="darkSecondaryText" tipo="notaLegendas">
              {usuario.cargo}
            </Tipografia>
            <Tipografia id="idEmailUsuarioLogadoInterno" cor="darkSecondaryText" tipo="notaLegendas">
              {usuario.email}
            </Tipografia>
          </Flex>
        </Flex>

        <Menu desktop className={classes.menuDesktop}>
          <Divider className={classes.divider} />
          {isSpotterFull && (
            <MenuItem
              href="https://exactsal.es/indique-spotter"
              target="_blank"
              rel="noopener noreferrer"
              primaryText={
                <Tipografia cor="darkSecondaryText">
                  {intl.formatMessage({ defaultMessage: "Indique e ganhe" })}
                </Tipografia>
              }
            />
          )}

          {featureToggles.menuExactClub &&
            isSpotterFull && (
              <MenuItem
                onClick={e => {
                  e.preventDefault();
                  handleRequestCloseMenuUsuario();
                  history.push("/spotter/exactclub");
                }}
                href={"/spotter/exactclub"}
                rel="noopener noreferrer"
                primaryText={
                  <Tipografia cor="darkSecondaryText">
                    {intl.formatMessage({ defaultMessage: "Exact Club" })}
                  </Tipografia>
                }
              />
            )}
          {isSpotterFull && (
            <MenuItem
              href="https://exactsal.es/agenda-espec"
              target="_blank"
              rel="noopener noreferrer"
              primaryText={
                <Tipografia cor="darkSecondaryText">
                  {intl.formatMessage({ defaultMessage: "Agenda de Especialistas" })}
                </Tipografia>
              }
            />
          )}
          {isSpotterFull && (
            <MenuItem
              href="https://exactsal.es/AtendimentoV2"
              target="_blank"
              rel="noopener noreferrer"
              primaryText={
                <Tipografia cor="darkSecondaryText">{intl.formatMessage({ defaultMessage: "Atendimento" })}</Tipografia>
              }
            />
          )}
          <MenuItem
            id={IdsInlineManual.POPOVER_SUA_CONTA}
            onClick={e => {
              e.preventDefault();
              handleRequestCloseMenuUsuario();
              history.push(linkSuaConta);
            }}
            rel="noopener noreferrer"
            href={linkSuaConta}
            primaryText={
              <Tipografia cor="darkSecondaryText">{intl.formatMessage({ defaultMessage: "Sua Conta" })}</Tipografia>
            }
          />
          <MenuItem
            href={linkCentralAjuda}
            target="_blank"
            rel="noopener noreferrer"
            primaryText={
              <Tipografia cor="darkSecondaryText">
                {intl.formatMessage({ defaultMessage: "Central de ajuda" })}
              </Tipografia>
            }
          />
          {isSpotterFull && (
            <MenuItem
              href={linkPluginLinkedin}
              target="_blank"
              rel="noopener noreferrer"
              primaryText={<Tipografia cor="darkSecondaryText">Plugin LinkedIn</Tipografia>}
            />
          )}
          <MenuItem
            primaryText={
              <Tipografia cor="darkSecondaryText">{intl.formatMessage({ defaultMessage: "Sair" })}</Tipografia>
            }
            onClick={() => {
              deslogar();
              history.push(linkLogOff);
            }}
          />
          <Divider className={classes.divider} />
          <Tipografia style={{ userSelect: "text" }} cor="darkSecondaryText" tipo="notaLegendas">
            {intl.formatMessage({ defaultMessage: "Ouvidoria" })} - ouvidoria@exactsales.com.br
          </Tipografia>
        </Menu>
      </Popover>
    </React.Fragment>
  );
};

MenuInformacoesConta.propTypes = {
  usuario: PropTypes.object.isRequired,
  menuUsuarioOpen: PropTypes.bool,
  anchorElMenuUsuario: PropTypes.object,
  handleClickMenuUsuario: PropTypes.func,
  handleRequestCloseMenuUsuario: PropTypes.func,
  linkSuaConta: PropTypes.string.isRequired,
  linkCentralAjuda: PropTypes.string.isRequired,
  linkPluginLinkedin: PropTypes.string.isRequired,
  linkLogOff: PropTypes.string.isRequired,
  classes: PropTypes.object,
};

export default injectSheet(styles)(MenuInformacoesConta);
