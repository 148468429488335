import React from "react";
import { EditorAttachFile } from "material-ui/svg-icons";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { injectIntl } from "react-intl";

import ActionsEditorTextoButton from "./Button";
import { injectSheet } from "../../../hocs";

const styles = {
  input: {
    display: "none !important",
  },
};

class ActionsEditorTextoAttachments extends React.PureComponent {
  handleOnClickAnexos = () => {
    this.upload.value = "";
    this.upload.click();
  };

  render() {
    const { attachmentClick, classes } = this.props;

    return (
      <React.Fragment>
        <ActionsEditorTextoButton
          tooltip={this.props.intl.formatMessage({ defaultMessage: "Anexar arquivos" })}
          IconComponent={EditorAttachFile}
          onClick={this.handleOnClickAnexos}
        />
        <input
          type="file"
          ref={input => {
            this.upload = input;
          }}
          className={classes.input}
          onChange={attachmentClick}
        />
      </React.Fragment>
    );
  }
}

ActionsEditorTextoAttachments.propTypes = {
  /** @ignore */
  classes: PropTypes.object,
  /** Evento onclick atribuido ao botão de attachment */
  attachmentClick: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

const enhance = compose(
  injectSheet(styles),
  injectIntl
);

export default enhance(ActionsEditorTextoAttachments);
