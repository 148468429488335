import React from "react";
import PropTypes from "prop-types";

function KeepMounted({ hide, fallback, children }) {
  return (
    <>
      <div style={{ display: hide ? "none" : null }}>{children}</div>
      {hide && fallback}
    </>
  );
}

KeepMounted.propTypes = {
  /** Determina se deve esconder o children */
  hide: PropTypes.bool.isRequired,
  /** Fallback component */
  fallback: PropTypes.element,
  children: PropTypes.node,
};

export default KeepMounted;
