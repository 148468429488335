export const FreshChatWidget = (tags, config, firstName = null, email = null) => {
  addSDK(tags, config, firstName, email);
  return { InitFreshChat };
};

function initialize(i, t, tags, config, firstName = null, email = null) {
  let e;
  const initiate = i.getElementById(t)
    ? () => InitFreshChat(tags, config, firstName, email)
    : (((e = i.createElement("script")).id = t),
      (e.src = "https://wchat.freshchat.com/js/widget.js"),
      (e.onload = () => InitFreshChat(tags, config, firstName, email)),
      i.head.appendChild(e));
  return initiate;
}

function initiateCall(tags, config, firstName = null, email = null) {
  initialize(document, "Freshchat-js-sdk", tags, config, firstName, email);
}

function addSDK(tags, config, firstName = null, email = null) {
  const addListener = window.addEventListener
    ? window.addEventListener("load", initiateCall(tags, config, firstName, email), !1)
    : window.attachEvent("load", initiateCall(tags, config, firstName, email), !1);
  return addListener;
}

export function InitFreshChat(tags, config = null, firstName = null, email = null) {
  window.fcWidget.init({
    token: "7c200b7b-b411-4336-aa40-3d034022b2e1",
    host: "https://wchat.freshchat.com",
    tags,
    open: false,
    config,
    firstName,
    email,
  });
  window.fcWidget.on("message:received", data => {
    if (data.message.messageFragments[0].content === "Abraço!") {
      setTimeout(() => {
        window.fcWidget.user.clear();
        window.fcWidget.close();
      }, 120000);
    }
  });
}

export function DestroyChat() {
  const chatAtivo = window.fcWidget?.isInitialized() || window.fcWidget?.isLoaded();
  if (!chatAtivo) return;

  // eslint-disable-next-line no-unused-expressions
  window.fcWidget?.destroy();
}
