import React from "react";
import { Tooltip } from "react-tippy";
import PropTypes from "prop-types";

import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = {
  labelStyle: {
    display: "block",
    width: 32,
    height: 32,
    borderRadius: 100,
    border: ({ cor, disabled }) => (!disabled ? `1px solid ${cor}` : ""),
    backgroundColor: ({ checked, cor, disabled }) => {
      if (disabled) {
        return "#eee";
      }
      return checked ? cor : "transparent";
    },
    cursor: ({ disabled }) => (!disabled ? "pointer" : ""),
    marginRight: 8,
  },
  checkboxStyle: { display: "none" },
};

const CorCheck = ({ id, tooltip, checked, handleOnClick, name, classes }) => (
  <React.Fragment>
    <Tooltip title={tooltip} position="bottom" duration={0} interactive>
      <label htmlFor={id} className={classes.labelStyle}>
        <input
          type="checkbox"
          id={id}
          onChange={handleOnClick}
          checked={checked}
          className={classes.checkboxStyle}
          name={name}
        />
      </label>
    </Tooltip>
  </React.Fragment>
);

CorCheck.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tooltip: PropTypes.string,
  checked: PropTypes.bool,
  handleOnClick: PropTypes.func,
  name: PropTypes.string,
  classes: PropTypes.object,
};

export default injectSheet(styles)(CorCheck);
