import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "react-jss";
import { compose } from "recompose";
import { useIntl } from "react-intl";

import { Flex, Tipografia } from "../..";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = theme => ({
  mensagemSelecionados: {
    height: 60,
    backgroundColor: `${theme.palette.primary1Color}`,
    color: `${theme.palette.text.lightPrimaryText}`,
    position: "absolute",
    width: "100%",
    left: 0,
    top: 77,
  },
  mensagem: {
    paddingLeft: 24,
  },
  botoes: {
    paddingRight: 24,
  },
});

const MensagemItemSelecionado = ({
  quantidadeSelecionados,
  botoes,
  botaoSelecionarTodos,
  mostraBotaoSelecionarTodosBD,
  botaoLimparSelecao,
  quantidadeTotalItens,
  classes,
}) => {
  const intl = useIntl();
  return (
    <Flex justifyContent="space-between" alignItems="center" className={classes.mensagemSelecionados}>
      <Tipografia className={classes.mensagem} cor="lightPrimaryText">
        {intl.formatMessage(
          { defaultMessage: "Selecionado {qtd} de {total}" },
          { qtd: quantidadeSelecionados, total: quantidadeTotalItens }
        )}
      </Tipografia>
      <Flex>
        {mostraBotaoSelecionarTodosBD && botaoSelecionarTodos}
        {botaoLimparSelecao}
      </Flex>
      <Flex className={classes.botoes}>{botoes}</Flex>
    </Flex>
  );
};

MensagemItemSelecionado.propTypes = {
  /** Quantidade de itens selecionados */
  quantidadeSelecionados: PropTypes.number.isRequired,

  /** Lista de botões contendo rótulo e ação */
  botoes: PropTypes.node.isRequired,

  /** Mostra o botaoSelecionarTodos somente se existir mais itens a serem carregados, do banco de dados */
  mostraBotaoSelecionarTodosBD: PropTypes.bool,

  /** Botão que seleciona todos os itens do banco de dados */
  botaoSelecionarTodos: PropTypes.node,

  /** Botão que desmarca todos os itens inclusive os do banco de dados */
  botaoLimparSelecao: PropTypes.node,

  /** Quantidade total de itens carregados na lista */
  quantidadeTotalItens: PropTypes.number,

  /** @ignore */
  classes: PropTypes.object,
};

const enhance = compose(
  injectSheet(styles),
  withTheme
);

export { MensagemItemSelecionado as MensagemItemSelecionadoPure };
export default enhance(MensagemItemSelecionado);
