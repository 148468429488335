import React from "react";
import PropTypes from "prop-types";
import { withStyles, Icon } from "@material-ui/core";

import { Flex } from "../../..";
import Navbar from "../../Navbar/Navbar";
import { usePageTitle } from "../../../../hooks";

function MensagemSolicitacaoCancelada({ classes }) {
  usePageTitle("Solicitação de alteração de plano");

  return (
    <>
      <Flex justifyContent="center" alignItems="center" flexDirection="column" className={classes.root}>
        <Navbar isRouterPublic />

        <div className={classes.container}>
          <div className={classes.mensagem}>
            <Icon className={classes.icon}>event_busy</Icon>
            <br />
            <br />
            Esse link não está mais válido, pois a solicitação foi cancelada.
            <div className={classes.subMensagem}>Você já pode fechar esta janela.</div>
          </div>
        </div>
      </Flex>
    </>
  );
}

export default withStyles(() => ({
  root: {
    margin: 0,
    backgroundColor: "#f1f1f1",
    color: "#333333",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "150px",
  },

  mensagem: {
    fontSize: "24px",
    textAlign: "center",
    width: "100%",
  },
  icon: {
    fontSize: "180px",
    color: "#a8a8a8",
  },
  subMensagem: {
    fontSize: "18px",
    marginTop: "24px",
  },
}))(MensagemSolicitacaoCancelada);

MensagemSolicitacaoCancelada.propTypes = {
  classes: PropTypes.object,
};
