import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Typography, withStyles } from "@material-ui/core";

ButtonLink.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

function ButtonLink({ children, className, classes, disabled, onClick, ...props }) {
  return (
    <Typography
      className={cn(className, classes.buttonLink, { [classes.buttonLinkDisabled]: !!disabled })}
      classes={{
        root: classes.root,
      }}
      component="span"
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default withStyles(theme => ({
  root: {
    display: "inline-flex",
    alignItems: "flex-end",
  },
  buttonLink: {
    color: "#457AB7",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonLinkDisabled: {
    color: theme.palette.text.disabled,
    "&:hover": {
      cursor: "default !important",
    },
  },
}))(ButtonLink);
