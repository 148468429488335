import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Loadable from "react-loadable";

import {
  ENTRADA,
  IMPORTANTE,
  ENVIADOS,
  ARQUIVADOS,
  LIXEIRA,
  SPAM,
  BUSCAR,
} from "../../../pages/central-emails/constantes";
import {
  TAB_CAMAPANHAS,
  TAB_LISTAS,
  TAB_TEMPLATES,
  TAB_DESCARTADOS,
  TAB_CAMPANHA_DASHBOARD,
  TAB_CAMPANHA_RESPOSTAS,
  TAB_CAMPANHA_ENVIOS,
  TAB_CAMPANHA_CONTATOS,
} from "../../../pages/cadencia-emails/constantesCadenciaEmails";
import BaseLeadsRoutes from "../../../pages/base-leads/Routes";
import ConfiguracoesRoutes from "../../../pages/configuracoes/Routes";
import routes from "./constantes";
import { Loading } from "../..";
import { ApiContextProvider } from "../../../context/ApiContext";
import LoginSSO from "./../../../pages/login-SSO/";
import ExactRoute from "../ExactRoute/ExactRoute";
import LoginSpotterADM from "../../../pages/login-spotter-ADM";

const LoadableHomeEmails = Loadable({
  loader: () => import(/* webpackChunkName: "HomeCentralEmails" */ "../../../pages/central-emails/Home/Home"),
  loading: () => <Loading isLoading />,
});

const LoadableHomeEmailsGerente = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomeCentralEmailsGerente" */ "../../../pages/central-emails/HomeGerente/HomeGerente"),
  loading: () => <Loading isLoading />,
});

const LoadableEquipe = Loadable({
  loader: () => import(/* webpackChunkName: "HomeDashboardPreVendedor" */ "../../../pages/equipe/Equipe"),
  loading: () => <Loading isLoading />,
});

const LoadableHomeDetalhesLead = Loadable({
  loader: () => import(/* webpackChunkName: "HomeDetalhesLead" */ "../../../pages/detalhes-lead"),
  loading: () => <Loading isLoading />,
});

const LoadableAnaliseLigacao = Loadable({
  loader: () => import("../../../pages/analise-ligacao"),
  loading: () => <Loading isLoading />,
});

const LoadableHomeTipoAtividade = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomeTipoAtividade" */ "../../../pages/atividades/containers/HomeTipoAtividade/HomeTipoAtividade"),
  loading: () => <Loading isLoading />,
});

const LoadableHomeAtividades = Loadable({
  loader: () => import(/* webpackChunkName: "HomeAtividades" */ "../../../pages/atividades"),
  loading: () => <Loading isLoading />,
});

const LoadableSemPermissaoAcesso = Loadable({
  loader: () => import(/* webpackChunkName: "SemPermissaoAcesso" */ "../SemPermissaoAcesso/SemPermissaoAcesso"),
  loading: () => <Loading isLoading />,
});

const LoadableFiltro = Loadable({
  loader: () => import(/* */ "../../../pages/filtro"),
  loading: () => <Loading isLoading />,
});
const LoadableResultadoBuscaGeral = Loadable({
  loader: () => import(/* */ "../../../pages/lead/components/ResultadoBuscaGeral/ResultadoBuscaGeral"),
  loading: () => <Loading isLoading />,
});

const LoadablePontuacao = Loadable({
  loader: () => import(/* webpackChunkName: "LoadablePontuacao" */ "../../../pages/pontuacao"),
  loading: () => <Loading isLoading />,
});

const LoadableHomeCadenciaEmails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "HomeCadenciaEmails" */ "../../../pages/cadencia-emails/containers/Home/Home"),
  loading: () => <Loading isLoading />,
});

const LoadableCadenciaEmailsNovaCampanha = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CadenciaEmailsNovaCampanha" */ "../../../pages/cadencia-emails/Campanhas/NovaCampanha"),
  loading: () => <Loading isLoading />,
});

const LoadableCadenciaEmailsCampanha = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CadenciaEmailsCampanha" */ "../../../pages/cadencia-emails/Campanhas/Campanha"),
  loading: () => <Loading isLoading />,
});

const LoadableCadenciaEmailsListaLeads = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CadenciaEmailsListaLeads" */ "../../../pages/cadencia-emails/Listas/Lista/Leads"),
  loading: () => <Loading isLoading />,
});

const LoadableCadenciaEmailListaProspects = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CadenciaEmailsListaProspects" */ "../../../pages/cadencia-emails/Listas/Lista/Prospects"),
  loading: () => <Loading isLoading />,
});

const LoadableCadenciaEmailListaDinamica = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CadenciaEmailsListaProspects" */ "../../../pages/cadencia-emails/Listas/Lista/Dinamica"),
  loading: () => <Loading isLoading />,
});

const LoadableCadenciaEmailListaDinamicaSegmentacao = Loadable({
  loader: () =>
    import(/* webpackChunkName: "CadenciaEmailsListaDinamica" */ "../../../pages/cadencia-emails/Listas/ListaDinamica"),
  loading: () => <Loading isLoading />,
});

const LoadableConfiguracoesCadenciaEmails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ConfiguracoesCadenciaEmails" */ "../../../pages/cadencia-emails/containers/Configuracoes/Configuracoes"),
  loading: () => <Loading isLoading />,
});

const LoadableConfiguracoesDominioAdicionarCadenciaEmails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ConfiguracoesDominioAdicionarCadenciaEmails" */ "../../../pages/cadencia-emails/containers/ConfiguracoesDominioAdicionar/ConfiguracoesDominioAdicionar"),
  loading: () => <Loading isLoading />,
});

const RelatorioDistribuicaoLeads = React.lazy(() => import("../../../pages/distribuicaoLeads/DistribuicaoLeads"));
const ListaLeads = React.lazy(() => import("../../../pages/lead/lista"));
const ImportarLeads = React.lazy(() => import("../../../pages/lead/importacao"));
const ImportarEmpresas = React.lazy(() => import("../../../pages/organizacao/importacao"));
const Searching = React.lazy(() => import("../../../pages/searching"));
const BusinessAnalytics = React.lazy(() => import("../../../pages/business-analytics"));
const ExactClub = React.lazy(() => import("../../../pages/exactClub"));
const Telefonia = React.lazy(() => import("../../../pages/telefonia"));
const WhatsappChatlist = React.lazy(() => import("../../../pages/whatsapp/ChatListView"));
const WhatsappRegistrationRequest = React.lazy(() => import("../../../pages/whatsapp/RegistrationRequest"));
const MenuConfiguravel = React.lazy(() => import("../../../pages/menu-configuravel"));
const OrganizacaoHomePage = React.lazy(() =>
  import("../../../pages/organizacao").then(module => ({ default: module.OrganizacaoHomePage }))
);
const OrganizacaoDetalhePage = React.lazy(() =>
  import("../../../pages/organizacao").then(module => ({ default: module.OrganizacaoDetalhePage }))
);

const Feedback = React.lazy(() => import("../../../pages/Feedback"));

const Workflow = React.lazy(() => import("../../../pages/workflow/ListaWorkflow"));
const EventoWorkflow = React.lazy(() => import("../../../pages/workflow/Evento"));

const Segmentacoes = React.lazy(() => import("../../../pages/segmentacoes/Segmentacoes"));

const Agenda = React.lazy(() => import("../../../pages/agenda"));
const ConfiguracoesAgenda = React.lazy(() => import("../../../pages/agenda/Configuracoes"));

const Atendimento = React.lazy(() => import("../../../pages/atendimento"));

const Reuniao = React.lazy(() => import("../../../pages/reuniao"));

const CompanionMagicWrite = React.lazy(() => import("../../../CompanionMentorIA"));

const DiscadorWebPhone = React.lazy(() => import("../../../pages/webphone/DiscadorWebphone"));

const BancoTalentos = React.lazy(() => import("../../../pages/bancoTalentos"));

const PlayerConferencia = React.lazy(() => import("../../../PlayerConferencia"));

const Conta = React.lazy(() => import("../../../pages/conta"));

const Dashboard = React.lazy(() => import("../../../pages/dashboard"));
const DashboardConfig = React.lazy(() => import("../../../pages/dashboard/DashboardConfig"));

const FormColetaDados = React.lazy(() => import("../../../pages/ColetaDados/FormColetaDados"));
const CallbackColetaDados = React.lazy(() => import("../../../pages/ColetaDados/CallbackColetaDados"));

const Login = React.lazy(() => import("../../../pages/login/Login"));

const verificarRota = (rota, abrirFormulario) => {
  if (abrirFormulario) return FormColetaDados;
  return rota;
};

const MainRoutes = item => (
  <ApiContextProvider>
    <Switch>
      {/* Rotas sem verificação do formulário */}
      <Route path={routes.LOGIN.route} component={Login} />
      <Route path={routes.NORMALLOGIN.route} component={Login} />
      <Route path={routes.LOGINSSO.route} component={LoginSSO} />
      <Route path={routes.LOGINSPOTTERADM.route} component={LoginSpotterADM} />
      <ExactRoute path="/magic/companion" roles={routes.MAGIC_COMPANION.roles} component={CompanionMagicWrite} />
      <ExactRoute path="/webphone/discador" roles={routes.DISCADOR_WEBPHONE.roles} component={DiscadorWebPhone} />
      <ExactRoute path="/spotter/banco-talentos" roles={routes.BANCO_TALENTOS.roles} component={BancoTalentos} />
      <ExactRoute path="/spotter/coleta-dados" component={FormColetaDados} />
      <ExactRoute path="/spotter/callback-coleta-dados" component={CallbackColetaDados} />

      {/* Rotas com verificação do formulário */}
      <Route path="/spotter/configuracoes" component={verificarRota(ConfiguracoesRoutes, item.abrirFormulario)} />
      <Route
        path="/spotter/base-leads"
        roles={routes.BASE_LEADS.roles}
        component={verificarRota(BaseLeadsRoutes, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/atendimento"
        roles={routes.ATENDIMENTO.roles}
        component={verificarRota(Atendimento, item.abrirFormulario)}
      />
      <ExactRoute
        path={routes.MENU_CONFIGURAVEL.route}
        roles={routes.MENU_CONFIGURAVEL.roles}
        component={verificarRota(MenuConfiguravel, item.abrirFormulario)}
        featureToggles={routes.MENU_CONFIGURAVEL.featureToggles}
      />
      <ExactRoute
        path={`/spotter/central-emails/(${ENTRADA}|${IMPORTANTE}|${ENVIADOS}|${ARQUIVADOS}|${LIXEIRA}|${SPAM}|${BUSCAR})/:idOuQuery?`}
        roles={routes.CENTRAL_EMAILS.roles}
        component={verificarRota(LoadableHomeEmails, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/central-emails"
        roles={routes.CENTRAL_EMAILS.roles}
        render={() => <Redirect to={`/spotter/central-emails/${ENTRADA}`} />}
      />
      <ExactRoute
        path="/spotter/configuracao/central-emails/templates"
        roles={routes.CONFIGURACAO_CENTRAL_EMAILS_TEMPLATES.roles}
        component={verificarRota(LoadableHomeEmailsGerente, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/detalhes-lead/:leadId"
        roles={routes.DETALHES_LEAD_LEADID.roles}
        component={verificarRota(LoadableHomeDetalhesLead, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/questionario"
        roles={routes.QUESTIONARIO.roles}
        component={verificarRota(LoadableFiltro, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/analise/:tipo?/:historicoLigacaoId?/:tempoLigacao?"
        roles={routes.ANALISE_LIGACAO_HISTORICOLIGACAO.roles}
        component={verificarRota(LoadableAnaliseLigacao, item.abrirFormulario)}
      />

      <ExactRoute
        path="/spotter/atividades/tipo-atividade"
        roles={routes.ATIVIDADES_TIPO_ATIVIDADE.roles}
        component={verificarRota(LoadableHomeTipoAtividade, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/atividades/:atividadeId"
        roles={routes.ATIVIDADES_ATIVIDADEID.roles}
        component={verificarRota(LoadableHomeAtividades, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/atividades"
        roles={routes.LEAD_OPORTUNIDADE_ATIVIDADES.roles}
        claims={routes.LEAD_OPORTUNIDADE_ATIVIDADES.claims}
        component={verificarRota(LoadableHomeAtividades, item.abrirFormulario)}
      />

      <ExactRoute
        path="/spotter/acesso-restrito/sem-permissao-acesso"
        roles={routes.SEM_PERMISSAO_ACESSO.roles}
        component={verificarRota(LoadableSemPermissaoAcesso, item.abrirFormulario)}
      />

      <ExactRoute
        path="/spotter/leads"
        exact
        roles={routes.LEAD_OPORTUNIDADE_LISTA_DE_LEADS.roles}
        component={verificarRota(ListaLeads, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/leads/importar"
        roles={routes.LEAD_OPORTUNIDADE_IMPORTAR_LEADS.roles}
        component={verificarRota(ImportarLeads, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/feedback/:reuniaoId"
        roles={routes.FEEDBACK_REUNIAO.roles}
        component={verificarRota(Feedback)}
      />
      <ExactRoute
        path="/spotter/resultado-busca-geral"
        roles={routes.RESULTADO_BUSCA_GERAL.roles}
        component={verificarRota(LoadableResultadoBuscaGeral, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/equipe/:currentTab"
        roles={routes.EQUIPE.roles}
        component={verificarRota(LoadableEquipe, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/pontuacao/extrato/pre-vendedor"
        roles={routes.PONTUACAO.roles}
        component={verificarRota(LoadablePontuacao, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/pontuacao/extrato/vendedor"
        roles={routes.PONTUACAO.roles}
        component={verificarRota(LoadablePontuacao, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/pontuacao/resumo/pre-vendedor"
        roles={routes.PONTUACAORESUMO.roles}
        component={verificarRota(LoadablePontuacao, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/pontuacao/resumo/vendedor"
        roles={routes.PONTUACAORESUMO.roles}
        component={verificarRota(LoadablePontuacao, item.abrirFormulario)}
      />
      <Redirect from="/spotter/pontuacao" to="/spotter/pontuacao/extrato/pre-vendedor" />
      <ExactRoute
        path="/spotter/lista-chats"
        customPermission={routes.TELEFONIA_CHATS_WHATSAPP.customPermission}
        roles={routes.TELEFONIA_CHATS_WHATSAPP.roles}
        component={verificarRota(WhatsappChatlist, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/whatsapp/:tab?"
        roles={routes.WHATSAPP.roles}
        component={verificarRota(WhatsappRegistrationRequest, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/telefonia"
        roles={routes.TELEFONIA.roles}
        component={verificarRota(Telefonia, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/organizacao"
        roles={routes.EMPRESA_ORGANIZACAO_LISTA_DE_EMPRESAS.roles}
        exact
        component={verificarRota(OrganizacaoHomePage, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/organizacao/importar"
        roles={routes.EMPRESA_ORGANIZACAO_IMPORTAR_EMPRESAS.roles}
        component={verificarRota(ImportarEmpresas, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/organizacao/:orgId"
        roles={routes.EMPRESA_ORGID.roles}
        exact
        component={verificarRota(OrganizacaoDetalhePage, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/workflow"
        roles={routes.WORKFLOW.roles}
        claims={routes.WORKFLOW.claims}
        exact
        component={verificarRota(Workflow, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/workflow/evento"
        roles={routes.WORKFLOW_EVENTO.roles}
        exact
        component={verificarRota(EventoWorkflow, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/workflow/evento/:workflowId"
        roles={routes.WORKFLOW_EVENTO_WORKFLOWID.roles}
        exact
        component={verificarRota(EventoWorkflow, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/segmentacoes"
        roles={routes.SEGMENTACOES.roles}
        featureToggles={routes.SEGMENTACOES.featureToggles}
        exact
        component={verificarRota(Segmentacoes, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/relatorio-distribuicao-leads"
        roles={routes.LEAD_OPORTUNIDADE_RELATORIO_DIST_DE_LEADS.roles}
        claims={routes.LEAD_OPORTUNIDADE_RELATORIO_DIST_DE_LEADS.claims}
        component={verificarRota(RelatorioDistribuicaoLeads, item.abrirFormulario)}
      />

      <ExactRoute
        path="/spotter/agenda/boxes"
        roles={routes.AGENDA_BOXES.roles}
        claims={routes.AGENDA_BOXES.claims}
        component={verificarRota(Agenda, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/agenda/calendario"
        roles={routes.AGENDA_CALENDARIO.roles}
        component={verificarRota(Agenda, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/agenda/padroes-agendamento"
        roles={routes.AGENDA_PADROES_AGENDAMENTO.roles}
        component={verificarRota(Agenda, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/agenda/solicitacoes-preenchimento"
        roles={routes.AGENDA_SOLICITACOES_PREENCHIMENTO.roles}
        component={verificarRota(Agenda, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/agenda/configuracao/:tab?"
        roles={routes.AGENDA_CONFIGURACAO_TAB.roles}
        component={verificarRota(ConfiguracoesAgenda, item.abrirFormulario)}
      />
      <Redirect from="/spotter/agenda" to="/spotter/agenda/boxes" />

      <ExactRoute
        exact
        path="/spotter/video-conferencia-player"
        roles={routes.VIDEO_CONFERENCIA_PLAYER.roles}
        component={verificarRota(PlayerConferencia, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/reuniao"
        roles={routes.REUNIAO.roles}
        component={verificarRota(Reuniao, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/searching/:categoria?"
        claims={routes.SEARCHING.claims}
        roles={routes.SEARCHING.roles}
        component={verificarRota(Searching, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/BA/Relatorio"
        claims={routes.BA.claims}
        roles={routes.BA.roles}
        component={verificarRota(BusinessAnalytics, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/exactclub"
        roles={routes.EXACT_CLUB.roles}
        featureToggles={routes.EXACT_CLUB.featureToggles}
        component={verificarRota(ExactClub, item.abrirFormulario)}
      />

      <ExactRoute
        path={`/spotter/cadencia-emails/campanha/:campanhaId/(${TAB_CAMPANHA_DASHBOARD}|${TAB_CAMPANHA_RESPOSTAS}|${TAB_CAMPANHA_ENVIOS}|${TAB_CAMPANHA_CONTATOS})`}
        claims={routes.CADENCIA_EMAILS_CAMPANHA_CAMPANHAID_TABDASHBOARD_TABRESPOSTAS_TABENVIOS_TABCONTATOS.claims}
        component={verificarRota(LoadableCadenciaEmailsCampanha, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/campanha/:campanhaId"
        claims={routes.CADENCIA_EMAILS_CAMPANHA_CAMPANHAID.claims}
        render={({ match }) => (
          <Redirect to={`/spotter/cadencia-emails/campanha/${match.params.campanhaId}/${TAB_CAMPANHA_DASHBOARD}`} />
        )}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/editar-campanha/:campanhaId"
        claims={routes.CADENCIA_EMAILS_EDITAR_CAMPANHA.claims}
        component={verificarRota(LoadableCadenciaEmailsNovaCampanha, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/nova-campanha"
        claims={routes.CADENCIA_EMAILS_NOVA_CAMPANHA.claims}
        component={verificarRota(LoadableCadenciaEmailsNovaCampanha, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/lista-leads/:listaId?"
        claims={routes.CADENCIA_EMAILS_LISTA_LEADS.claims}
        component={verificarRota(LoadableCadenciaEmailsListaLeads, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/lista-prospects/:listaId?"
        claims={routes.CADENCIA_EMAILS_LISTA_PROSPECTS.claims}
        component={verificarRota(LoadableCadenciaEmailListaProspects, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/lista-dinamica/segmentacao/:listaId?"
        claims={routes.CADENCIA_EMAILS_LISTA_DINAMICA_SEGMENTACAO.claims}
        component={verificarRota(LoadableCadenciaEmailListaDinamicaSegmentacao, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/lista-dinamica/:listaId?"
        claims={routes.CADENCIA_EMAILS_LISTA_DINAMICA.claims}
        component={verificarRota(LoadableCadenciaEmailListaDinamica, item.abrirFormulario)}
      />
      <ExactRoute
        path={`/spotter/cadencia-emails/(${TAB_CAMAPANHAS}|${TAB_LISTAS}|${TAB_TEMPLATES}|${TAB_DESCARTADOS})`}
        claims={routes.CADENCIA_EMAILS_TABCAMPANHAS_TABLISTAS_TABTEMPLATES_TABDESCARTADOS.claims}
        component={verificarRota(LoadableHomeCadenciaEmails, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/configuracoes/dominio"
        claims={routes.CADENCIA_EMAILS_CONFIGURACOES_DOMINIO.claims}
        component={verificarRota(LoadableConfiguracoesDominioAdicionarCadenciaEmails, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails/configuracoes"
        claims={routes.CADENCIA_EMAILS_CONFIGURACOES.claims}
        component={verificarRota(LoadableConfiguracoesCadenciaEmails, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/cadencia-emails"
        claims={routes.CADENCIA_EMAILS.claims}
        render={() => <Redirect to={`/spotter/cadencia-emails/${TAB_CAMAPANHAS}`} />}
      />

      <ExactRoute
        path="/spotter/conta/sua-conta"
        roles={routes.CONTA_SUA_CONTA.roles}
        component={verificarRota(Conta, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/conta/seguranca"
        roles={routes.CONTA_SEGURANCA.roles}
        component={verificarRota(Conta, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/conta/termos-de-uso"
        roles={routes.CONTA_TERMOS_DE_USO.roles}
        component={verificarRota(Conta, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/conta/financeiro"
        roles={routes.CONTA_FINANCEIRO.roles}
        component={verificarRota(Conta, item.abrirFormulario)}
      />
      <ExactRoute
        path="/spotter/conta/mais-recursos"
        roles={routes.CONTA_MAIS_RECURSOS.roles}
        component={verificarRota(Conta, item.abrirFormulario)}
      />
      <ExactRoute path="/spotter/conta/painel-financeiro" component={verificarRota(Conta, item.abrirFormulario)} />
      {/* <Route path="/spotter/spotter/conta/funcionalidades-contratadas" component={Conta} /> */}
      <ExactRoute
        path="/spotter/conta/webphone"
        roles={routes.CONTA_WEBPHONE.roles}
        component={verificarRota(Conta, item.abrirFormulario)}
      />
      <Redirect from="/spotter/conta" to="/spotter/conta/sua-conta" />

      <ExactRoute path="/spotter/dashboard" exact component={verificarRota(Dashboard, item.abrirFormulario)} />
      <ExactRoute
        path="/spotter/dashboard/configurar"
        exact
        component={verificarRota(DashboardConfig, item.abrirFormulario)}
      />
      <Redirect to="/spotter/dashboard" />

      <Redirect from="/" to={routes.LOGIN.route} />
    </Switch>
  </ApiContextProvider>
);

export default MainRoutes;
