import React from "react";
import { withStyles, Avatar, Link } from "@material-ui/core";

import iconeLogoNome from "../../../_common/icones/Logo_RD_Station.svg";

function LinkLogoRDMentorIA({ classes }) {
  return (
    <Link target="_blank" rel="noreferrer" href="https://exactsal.es/mentoria">
      <Avatar aria-label="recipe" src={iconeLogoNome} className={classes.logo} />
    </Link>
  );
}

export default withStyles(() => ({
  logo: {
    width: 170,
    height: 33,
    borderRadius: "0%",
  },
}))(LinkLogoRDMentorIA);
