import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "react-jss";
import { compose } from "recompose";
import cn from "classnames";

import { Flex } from "../../../_common";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = {
  root: {
    width: 32,
    height: 32,
    marginLeft: 8,
    borderRadius: "37%",
    backgroundColor: ({ theme }) => theme.palette.text.darkDividers,
    color: ({ theme }) => theme.palette.text.darkSecondaryText,
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      backgroundColor: ({ theme }) => theme.palette.icons.darkInactiveIcon,
      color: ({ theme }) => theme.palette.backgroundRowHover,
      fontWeight: "bold",
    },
  },
};

const BadgeButton = ({ symbol, value, handleOnClick, className, classes }) => (
  <Flex className={cn(classes.root, className)} center onClick={handleOnClick}>
    <span>
      {symbol}
      {value}
    </span>
  </Flex>
);

BadgeButton.propTypes = {
  className: PropTypes.string,
  /** Simbolo a ser concatenado à esquerda do valor */
  symbol: PropTypes.string,

  /** Valor a ser apresentado */
  value: PropTypes.number.isRequired,

  /** Função a ser executada no evento onClick */
  handleOnClick: PropTypes.func.isRequired,

  classes: PropTypes.object,
};

const enhance = compose(
  withTheme,
  injectSheet(styles)
);
export default enhance(BadgeButton);
