import React from "react";
import PropTypes from "prop-types";
import { Icon, withStyles } from "@material-ui/core";
import { Tooltip } from "react-tippy";
import Flex from "../Flex";

IconAction.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  classes: PropTypes.object,
};

function IconAction({ icon, text, onClick, disabled, title, classes }) {
  const contentClass = disabled ? classes.contentDisabled : classes.content;
  return (
    <Tooltip title={disabled ? "" : title || "Ver detalhes"}>
      <Flex className={disabled ? classes.rootDisabled : classes.root} center onClick={onClick}>
        <Icon className={`material-icons ${classes.icon} ${contentClass}`} fontSize="inherit">
          {icon}
        </Icon>
        <span className={contentClass}>{text}</span>
      </Flex>
    </Tooltip>
  );
}

export default withStyles(theme => ({
  root: {
    "&:hover": {
      cursor: "pointer",
    },
    "&:hover $content": {
      color: theme.palette.icons.darkFocusedIcon,
    },
  },
  content: {
    color: theme.palette.icons.darkActiveIcon,
  },
  contentDisabled: {
    color: theme.palette.icons.darkInactiveIcon,
  },
  icon: {
    marginRight: 4,
  },
}))(IconAction);
