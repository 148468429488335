import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import MensagemResponsavel from "./MensagemResponsavel";
import env from "../../src/../../../environment";

function ConfirmaAlteracaoPlano() {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");
  const baseUrl = env.REACT_APP_BASE_URL;
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        `${baseUrl}/Api/Pipeline/FuncionalidadePlano/ConfirmaAlteracaoPlano?code=${code}`
      );
      setData(response.data);
    }
    fetchData();
  }, []);

  return <MensagemResponsavel nomeEmpresa={data} />;
}

export default ConfirmaAlteracaoPlano;
