import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { withTheme } from "react-jss";
import cn from "classnames";

import { Flex } from "../../../_common/index";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = {
  campo: ({ height }) => ({ height: height || 68, marginBottom: 16 }),
  iconeInput: { width: 40, paddingTop: 24 },
};

const InputWithIcon = ({ Icon, children, className, theme, classes }) => (
  <Flex className={cn(classes.campo, className)}>
    <div className={classes.iconeInput}>
      <Icon nativeColor={theme.palette.icons.darkActiveIcon} />
    </div>
    {children}
  </Flex>
);

InputWithIcon.propTypes = {
  /** Referência do SvgIcon do material-ui. https://material-ui.com/style/icons/ */
  Icon: PropTypes.func.isRequired,
  /** Componente Input a ser renderizado */
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  classes: PropTypes.object,
};

export { InputWithIcon as InputWithIconPure };

const enhance = compose(
  withTheme,
  injectSheet(styles)
);

export default enhance(InputWithIcon);
