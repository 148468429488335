import { createIntlInfosMenu } from "../../constantesMenu";
import { INFOS_MENU_ROTAS } from "../../constantesMenuRotas";

export const estadoMenu = { ABERTO: "ABERTO", FECHADO: "FECHADO", INICIADO: "INICIADO" };

export function menuItems(intl) {
  const eItemsMenu = createIntlInfosMenu(intl);
  return Object.keys(eItemsMenu).map(menuItemKey => {
    const correspondingMenuItem = INFOS_MENU_ROTAS[menuItemKey];
    if (correspondingMenuItem) {
      return {
        ...correspondingMenuItem,
        title: eItemsMenu[menuItemKey].title,
        accordionItems:
          INFOS_MENU_ROTAS[menuItemKey].accordionItems &&
          INFOS_MENU_ROTAS[menuItemKey].accordionItems.map(accordionItem => {
            const correspondingAccordionItem = eItemsMenu[menuItemKey].accordionItems.find(
              item => item.itemAccordionKey === accordionItem.itemAccordionKey
            );

            return {
              ...accordionItem,
              title: correspondingAccordionItem.title,
            };
          }),
      };
    }
    return null;
  });
}
