import React from "react";
import PropTypes from "prop-types";

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    error: null,
  };

  componentDidCatch(error) {
    // eslint-disable-next-line
    console.error(error);
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    return null;
  }
}
