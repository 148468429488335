import React from "react";
import cn from "classnames";
import { pure, compose } from "recompose";

import CircularProgress from "material-ui/CircularProgress";
import PropTypes from "prop-types";
import injectSheet from "../../hocs/injectSheet/injectSheet";
import KeepMounted from "../KeepMounted";

const styles = {
  loading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
};

const Loading = ({ classes, isLoading, children, sheet, className, size, keepMounted, ...rest }) => (
  <>
    {!keepMounted && (
      <>
        {isLoading ? <LoadingIndicator className={cn(className, classes.loading)} size={size} {...rest} /> : children}
      </>
    )}

    {keepMounted && (
      <KeepMounted
        hide={isLoading}
        fallback={<LoadingIndicator className={cn(className, classes.loading)} size={size} {...rest} />}
      >
        {children}
      </KeepMounted>
    )}
  </>
);

function LoadingIndicator({ className, size, ...rest }) {
  return (
    <div id="loadingCircularProgress" className={className} {...rest}>
      <CircularProgress size={size || 80} thickness={5} />
    </div>
  );
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

Loading.propTypes = {
  /** @ignore */
  classes: PropTypes.object,
  /** @ignore */
  sheet: PropTypes.object,
  /** Indica se o elemento Loader vai ser exibido. */
  isLoading: PropTypes.bool.isRequired,
  /** Conteúdo para ser exibido caso os `isLoading = false`. */
  children: PropTypes.node,
  /** Nome da classe css a ser aplicada no componente. <br />
   *  Se utilizar o `injectsheet`: usar `{classes.nome_da_classe}`
   */
  className: PropTypes.string,
  /** Tamanho do elemento Loader. */
  size: PropTypes.number,
  /** Mantém o componente children montado */
  keepMounted: PropTypes.bool,
};

const enhancer = compose(
  injectSheet(styles),
  pure
);

export default enhancer(Loading);
