import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Typography, Button, withStyles } from "@material-ui/core";
import ErrorOutlined from "@material-ui/icons/ErrorOutlined";

const styles = {
  icon: {
    fontSize: "11rem",
  },
  text: {
    fontSize: "1rem",
  },
};

const MessageEmpty = ({ title, text, textButton, classes, styleTitle, ...props }) => (
  <Fragment>
    <ErrorOutlined color="disabled" className={classes.icon} />
    {title ? (
      <Typography className={classes.text} color="textSecondary">
        {title}
      </Typography>
    ) : null}
    <Typography className={styleTitle ? this : classes.text} color="textSecondary">
      {text}
    </Typography>
    {textButton && (
      <Button color="primary" {...props}>
        {String(textButton).toUpperCase()}
      </Button>
    )}
  </Fragment>
);

MessageEmpty.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  textButton: PropTypes.string,
  classes: PropTypes.object,
  styleTitle: PropTypes.object,
};

export default withStyles(styles)(MessageEmpty);
