import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import cn from "classnames";
import { withTheme } from "react-jss";
import { Tooltip } from "react-tippy";
import ButtonBase from "@material-ui/core/ButtonBase";
import injectSheet from "../../../hocs/injectSheet";

const styles = {
  root: {
    width: "20px !important",
    height: "20px !important",
    padding: 4,
    boxSizing: "content-box",
    cursor: "pointer",
    borderRadius: 4,
    margin: "0 2px",
    fill: props => (props.estaClicado ? "black" : props.theme.palette.icons.darkActiveIcon),
    backgroundColor: props => (props.estaClicado ? `${props.theme.palette.text.darkDividers}` : ""),
    "&:hover": {
      fill: props => props.theme.palette.icons.darkFocusedIcon,
      backgroundColor: props => `${props.estaClicado ? "" : props.theme.palette.clockCircleColor}`,
    },
    "&:active": {
      fill: "black",
      backgroundColor: props => props.theme.palette.text.darkDividers,
    },
  },
  disabled: { opacity: 0.3 },
};

const ActionsEditorTextoButton = ({
  IconComponent,
  classes,
  theme,
  active,
  sheet,
  tooltip,
  autoFocusEditor,
  IconClass,
  estaClicado,
  disabled,
  ...rest
}) => {
  const component = (
    <ButtonBase disabled={disabled} classes={{ disabled: classes.disabled }}>
      <IconComponent
        style={styles.root}
        tabIndex="0"
        data-auto-focus-editor={!!autoFocusEditor}
        className={cn(classes.root, { active }, IconClass)}
        {...rest}
      />
    </ButtonBase>
  );

  return tooltip && !disabled ? (
    <Tooltip title={tooltip} position="top" duration={0}>
      {component}
    </Tooltip>
  ) : (
    component
  );
};

ActionsEditorTextoButton.propTypes = {
  IconComponent: PropTypes.func.isRequired,
  autoFocusEditor: PropTypes.bool,
  classes: PropTypes.object,
  theme: PropTypes.object,
  active: PropTypes.bool,
  sheet: PropTypes.object,
  tooltip: PropTypes.string,
  IconClass: PropTypes.string,
  estaClicado: PropTypes.bool,
  disabled: PropTypes.bool,
};

const enhance = compose(
  withTheme,
  injectSheet(styles)
);

export default enhance(ActionsEditorTextoButton);
