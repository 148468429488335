import React from "react";
import PropTypes from "prop-types";

const EditorTextoDecoratorDinamico = NomeTag => {
  const DecoratorDinamico = ({ contentState, entityKey, children }) => {
    const { attributes } = contentState.getEntity(entityKey).getData();
    return <NomeTag {...attributes}>{children}</NomeTag>;
  };
  DecoratorDinamico.propTypes = {
    contentState: PropTypes.object,
    entityKey: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  };

  return DecoratorDinamico;
};

EditorTextoDecoratorDinamico.propTypes = {
  NomeTag: PropTypes.string.isRequired,
};

export default EditorTextoDecoratorDinamico;
