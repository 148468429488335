import React from "react";
import PropTypes from "prop-types";
import { TextField } from "material-ui";
import IconButton from "material-ui/IconButton";
import { Flex } from "../..";
import temaExact from "../../utils/temaExact";
import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = {
  textField: {
    paddingRight: 20,
  },
  campoTextoLimpavel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover $iconeClass": {
      display: "block !important",
    },
  },
  button: {
    width: 0,
    padding: 0,
    left: -21,
  },
  iconStyle: {
    color: temaExact.palette.text.darkHintText,
  },
  iconeClass: {
    display: "none !important",
  },
};

const CampoTextoLimpavel = ({ classes, handleLimparCampo, ...props }) => (
  <Flex className={classes.campoTextoLimpavel}>
    <TextField className={classes.textField} {...props} />
    <IconButton
      iconClassName="material-icons"
      onClick={handleLimparCampo}
      color={temaExact.palette.icons.lightFocusedIcon}
      style={styles.button}
      iconStyle={styles.iconStyle}
      className={classes.iconeClass}
    >
      cancel
    </IconButton>
  </Flex>
);
CampoTextoLimpavel.propTypes = {
  classes: PropTypes.object,
  handleLimparCampo: PropTypes.func,
};
export default injectSheet(styles)(CampoTextoLimpavel);
