import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import injectSheet from "../../hocs/injectSheet/injectSheet";

const styles = {
  root: {
    backgroundColor: "rgba(0,0,0,.12)",
    height: 1,
    width: 1,
  },
};

const Divider = ({ classes, className, style }) => (
  <div style={style} className={classnames(classes.root, className)} />
);

Divider.propTypes = {
  /** @ignore */
  classes: PropTypes.object,
  /** Classname a ser aplicado no root do componente */
  className: PropTypes.string,
  /** Style a ser aplicado no root do componente */
  style: PropTypes.object,
};
Divider.defaultProps = { className: undefined, style: undefined };

export default injectSheet(styles)(Divider);
