import React from "react";

import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { FlatButton, TextField, Popover, Menu, MenuItem } from "material-ui";

import { Flex } from "../../..";
import InnerModal from "../InnerModal";

const ActionsEditorTexto = ({
  children,
  openModalLink = false,
  handleModalLinkCancel,
  handleModalLinkOK,
  urlValue,
  textoSerExibido,
  onChangeTextoExbido,
  onChangeLinkPara,
  onClickTamanhoFonte,
  anchorElPopoverTamanhoFonte,
  handleClosePopoverTamanhoFonte,
  intl,
}) => {
  const actions = [
    <FlatButton
      key="btnCancelar"
      label={intl.formatMessage({ defaultMessage: "CANCELAR" })}
      default
      onClick={handleModalLinkCancel}
    />,
    <FlatButton
      disabled={!urlValue}
      key="btnOk"
      label={intl.formatMessage({ defaultMessage: "OK" })}
      primary
      onClick={handleModalLinkOK}
    />,
  ];

  return (
    <Flex style={{ width: "100%" }} flexDirection="row" alignItems="center">
      <InnerModal
        title={intl.formatMessage({ defaultMessage: "Editar Link" })}
        open={openModalLink}
        actions={actions}
        onClose={handleModalLinkCancel}
      >
        <TextField
          floatingLabelText={intl.formatMessage({ defaultMessage: "Texto a ser exibido" })}
          floatingLabelFixed
          value={textoSerExibido}
          onChange={onChangeTextoExbido}
          autoFocus={!textoSerExibido}
          fullWidth
        />
        <br />
        <TextField
          floatingLabelText={intl.formatMessage({ defaultMessage: "Link para" })}
          floatingLabelFixed
          value={urlValue}
          autoFocus={!urlValue && textoSerExibido}
          onChange={onChangeLinkPara}
          fullWidth
        />
      </InnerModal>

      <Popover
        open={anchorElPopoverTamanhoFonte != null}
        anchorEl={anchorElPopoverTamanhoFonte}
        anchorOrigin={{ horizontal: "middle", vertical: "top" }}
        targetOrigin={{ horizontal: "middle", vertical: "bottom" }}
        onRequestClose={handleClosePopoverTamanhoFonte}
      >
        <Menu>
          <MenuItem onClick={() => onClickTamanhoFonte("FONT_SIZE_11")}>
            <span style={{ fontSize: 11 }}>{intl.formatMessage({ defaultMessage: "Pequeno" })}</span>
          </MenuItem>
          <MenuItem onClick={() => onClickTamanhoFonte("FONT_SIZE_14")}>
            <span style={{ fontSize: 14 }}>{intl.formatMessage({ defaultMessage: "Normal" })}</span>
          </MenuItem>
          <MenuItem onClick={() => onClickTamanhoFonte("FONT_SIZE_18")}>
            <span style={{ fontSize: 18 }}>{intl.formatMessage({ defaultMessage: "Grande" })}</span>
          </MenuItem>
          <MenuItem onClick={() => onClickTamanhoFonte("FONT_SIZE_32")}>
            <span style={{ fontSize: 32 }}>{intl.formatMessage({ defaultMessage: "Enorme" })}</span>
          </MenuItem>
        </Menu>
      </Popover>

      {children.map(x => x)}
    </Flex>
  );
};

ActionsEditorTexto.propTypes = {
  /** Função responsável por fechar a modal. */
  handleModalLinkCancel: PropTypes.func,
  /**
   * Função assíncrona responsável por confirmar a ação ao clicar em OK.
   * @returns {Promise} Resolvida quando a ação é completada.
   */
  handleModalLinkOK: PropTypes.func,
  /** Indica se a modal de inserir link deve estar aberta ou não */
  openModalLink: PropTypes.bool,
  /** Valor a ser apresentado no input da modal de inserir link 'Link para:' */
  urlValue: PropTypes.string,
  /** Valor a ser apresentado no input da modal de inserir link 'Texto a ser exibido' */
  textoSerExibido: PropTypes.string,
  /**
   * Evento onchange atribuido ao input da modal de inserir link 'Texto a ser exibido'
   * @param {Event} Evento
   */
  onChangeTextoExbido: PropTypes.func,
  /**
   * Evento onchange atribuido ao input da modal de inserir link 'Link para:'
   * @param {Event} Evento
   */
  onChangeLinkPara: PropTypes.func,
  /** Itens a serem renderizados nas actions */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClickTamanhoFonte: PropTypes.func,
  anchorElPopoverTamanhoFonte: PropTypes.object,
  handleClosePopoverTamanhoFonte: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(ActionsEditorTexto);
