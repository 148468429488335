import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { withStyles, Drawer, IconButton, Button, Typography, Card, CardContent, Tooltip } from "@material-ui/core";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import IconeBlackout from "../../../_common/icones/Logo_Redondo_RD_Blackout.gif";
import { Flex, createSnackbar } from "../..";
import { TIPO_PROMPT_MAGIC_WRITE } from "../../constantes";
import Mensagem from "../../../CompanionMentorIA/Mensagem";
import { useUsuario } from "../../../hooks";
import LinkLogoRDMentorIA from "../LinkLogoRDMentorIA";

const PESQUISA = {
  pergunta: "",
  resposta: "",
};

function AbaRDMentorIA({ classes, aberto, estadoAba, limparHistorico, tipo = TIPO_PROMPT_MAGIC_WRITE.COMPANION }) {
  const intl = useIntl();
  const { isGerente } = useUsuario();
  const [proximoPasso, setProximoPasso] = useState(false);
  const [pesquisas, setPesquisas] = useState({ listaPesquisa: [] });
  const [buscandoMagic, setBuscandoMagic] = useState(false);
  const [fieldRows, setFieldRows] = useState("2");

  const listaPromptsPadrao = [
    intl.formatMessage({ defaultMessage: "Me mostre exemplos de gatilhos mentais." }),
    intl.formatMessage({ defaultMessage: "Qual a melhor cadência para prospectar?" }),
    intl.formatMessage({ defaultMessage: "Quais são as dores do mercado de tecnologia?" }),
    intl.formatMessage({ defaultMessage: "Sugira uma abertura para a minha abordagem comercial." }),
    intl.formatMessage({ defaultMessage: "Liste maneiras de passar pelo gatekeeper." }),
  ];

  const adicionarMensagem = item => {
    const requisicao = pesquisas.listaPesquisa;
    requisicao.push(item);
    setPesquisas({ ...pesquisas, listaPesquisa: requisicao });
  };

  const mostrarNovaResposta = () => {
    const mensagensContainer = document.getElementById("mensagens-container");
    mensagensContainer.scrollTop = mensagensContainer.scrollHeight;
  };

  const mostrarHistorico = status => {
    setProximoPasso(status);
    if (status) {
      setBuscandoMagic(true);
      setTimeout(() => {
        mostrarNovaResposta();
      }, "1000");
      setBuscandoMagic(false);
    }
  };

  async function buscarMagicWrite(valoresFormulario) {
    const textSemHistorico = { listaPesquisa: [] };
    const perguntaUsuario = valoresFormulario.pergunta;
    valoresFormulario.pergunta = "";
    if (limparHistorico) {
      textSemHistorico.listaPesquisa.push({ magic: false, texto: perguntaUsuario });
      setPesquisas(textSemHistorico);
    } else adicionarMensagem({ magic: false, texto: perguntaUsuario });
    try {
      const resp = await axios.get(
        `/api/pipeline/IntegracaoMagicWrite/ConsultarCompanion?prompt=${perguntaUsuario}&tipoPromptMagicWrite=${tipo}`
      );
      if (resp.data) {
        if (limparHistorico) {
          textSemHistorico.listaPesquisa.push({ magic: true, texto: resp.data });
          setPesquisas(textSemHistorico);
        } else adicionarMensagem({ magic: true, texto: resp.data });
        mostrarNovaResposta();
      }
    } catch (erro) {
      createSnackbar(intl.formatMessage({ defaultMessage: "Não foi possível realizar a sua pesquisa" }));
    } finally {
      setBuscandoMagic(false);
    }
  }

  async function onSave(values) {
    if (!proximoPasso) setProximoPasso(true);
    setBuscandoMagic(true);
    buscarMagicWrite(values);
  }

  const validate = values => {
    const errors = {};
    if (!values.pergunta) errors.dataInicial = intl.formatMessage({ defaultMessage: "*Campo obrigatório" });
    return errors;
  };

  const alterarFieldRows = () => {
    switch (true) {
      case window.outerHeight >= 1280:
        setFieldRows("45");
        break;
      case window.outerHeight >= 1030:
        setFieldRows("24");
        break;
      case window.outerHeight >= 720:
        setFieldRows("6");
        break;
      case window.innerHeight >= 585:
        setFieldRows("5");
        break;
      case window.innerHeight >= 550:
        setFieldRows("3");
        break;
      default:
    }
  };

  useEffect(() => {
    alterarFieldRows();
  }, []);

  return (
    <Drawer anchor="right" open={aberto}>
      {buscandoMagic && (
        <div id="blackout-magic-write" className={classes.blackoutMagicWrite}>
          <img className={classes.imgBlackout} src={IconeBlackout} alt="blackout-magic-write" />
        </div>
      )}
      <Flex flexDirection="column" className={classes.containerGaveta}>
        <Flex justifyContent="space-between" alignItems="center" className={classes.containerTitulo}>
          <Flex justifyContent="flex-start">
            <LinkLogoRDMentorIA />
          </Flex>
          <Flex justifyContent="flex-end">
            <Flex justifyContent="space-between">
              <Flex justifyContent="flex-start">
                {proximoPasso && (
                  <Tooltip
                    title={intl.formatMessage({
                      defaultMessage: "Voltar",
                    })}
                  >
                    <IconButton aria-label="Back" onClick={() => mostrarHistorico(false)} className={classes.botaoAcao}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Flex>
              <Flex justifyContent="flex-end">
                <IconButton aria-label="Close" onClick={() => estadoAba(false)} className={classes.botaoAcao}>
                  <CloseIcon />
                </IconButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {!proximoPasso && (
          <Flex
            flexDirection="column"
            alignItems="column"
            justifyContent="space-between"
            className={classes.fullHeight}
          >
            <Flex flexDirection="column" justifyContent="flex-start" className={classes.containerAcoes}>
              <Typography className={classes.tituloAcoes}>
                {intl.formatMessage({ defaultMessage: "Como posso ajudar?" })}
              </Typography>
              <Flex flexDirection="column" alignItems="column" style={{ height: 282 }}>
                {listaPromptsPadrao.map((item, index) => (
                  <Card
                    key={index}
                    className={classes.cardPrompt}
                    onClick={() => onSave({ pergunta: item, resposta: "" })}
                  >
                    <CardContent className={classes.cardContentPrompt}>
                      <Typography style={{ fontSize: 16, fontWeight: 400 }}>{item}</Typography>
                    </CardContent>
                  </Card>
                ))}
              </Flex>
            </Flex>
            <Flex flexDirection="column" justifyContent="flex-end" className={classes.fullHeight}>
              <Formik
                initialValues={PESQUISA}
                onSubmit={onSave}
                validate={validate}
                enableReinitialize
                className={classes.formAcoesContent}
              >
                {() => (
                  <Form className={classes.formAcoesContent}>
                    <Flex flexDirection="column" className={classes.fullHeight}>
                      <Flex className={classes.fullHeight}>
                        <Field
                          fullWidth
                          label={intl.formatMessage({
                            defaultMessage: "Sobre o que você quer escrever?",
                          })}
                          name="pergunta"
                          variant="outlined"
                          component={TextField}
                          multiline
                          rows={fieldRows}
                          className={classes.fullHeight}
                          InputProps={{ style: { height: "100%", marginBottom: 24, padding: 8 } }}
                        />
                      </Flex>
                      <Flex justifyContent="space-between" alignItems="center">
                        {isGerente && (
                          <Flex justifyContent="flex-end">
                            <IconButton className={classes.ajustes} aria-label="Settings">
                              <SettingsIcon />
                            </IconButton>
                          </Flex>
                        )}
                        <Flex justifyContent="flex-end">
                          <Button disabled={buscandoMagic} variant="contained" color="primary" type="submit">
                            {intl.formatMessage({ defaultMessage: "Enviar" })}
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Flex>
          </Flex>
        )}
        {proximoPasso && (
          <Flex flexDirection="column" alignItems="column" justifyContent="space-between" style={{ height: "100%" }}>
            <Flex flexDirection="column" justifyContent="flex-start" className={classes.containerPesquisas}>
              <Flex
                id="mensagens-container"
                flexDirection="column"
                className={classes.itensPesquisa}
                style={{ height: `calc(${window.innerHeight}px - 246px)` }}
              >
                {pesquisas?.listaPesquisa.map((item, index) => <Mensagem key={index} mensagem={item} />)}
              </Flex>
            </Flex>
            <Flex flexDirection="column" justifyContent="flex-end" style={{ margin: 12 }}>
              <Formik
                initialValues={PESQUISA}
                onSubmit={onSave}
                validate={validate}
                enableReinitialize
                className={classes.formContent}
              >
                {() => (
                  <Form className={classes.formContent}>
                    <Flex flexDirection="column">
                      <Flex>
                        <Field
                          fullWidth
                          label={intl.formatMessage({
                            defaultMessage: "Sobre o que você quer escrever?",
                          })}
                          name="pergunta"
                          variant="outlined"
                          component={TextField}
                          multiline
                          rows="2"
                        />
                      </Flex>
                      <Flex justifyContent="space-between" alignItems="center">
                        {isGerente && (
                          <Flex justifyContent="flex-end">
                            <IconButton className={classes.ajustes} aria-label="Settings">
                              <SettingsIcon />
                            </IconButton>
                          </Flex>
                        )}
                        <Flex justifyContent="flex-end">
                          <Button disabled={buscandoMagic} variant="contained" color="primary" type="submit">
                            {intl.formatMessage({ defaultMessage: "Enviar" })}
                          </Button>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Drawer>
  );
}

AbaRDMentorIA.propTypes = {
  aberto: PropTypes.bool,
  estadoAba: PropTypes.func,
  limparHistorico: PropTypes.bool,
  tipo: PropTypes.number,
};

export default withStyles(() => ({
  containerGaveta: {
    height: "100%",
    width: 500,
    padding: 24,
  },
  containerTitulo: {
    height: 32,
    marginBottom: 16,
  },
  botaoAcao: {
    width: 32,
    height: 32,
  },
  containerAcoes: {
    margin: 0,
  },
  tituloAcoes: {
    marginBottom: 8,
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "0.15px",
  },
  cardPrompt: {
    margin: 4,
    borderRadius: 4,
    cursor: "pointer",
  },
  cardContentPrompt: {
    padding: "12px!important",
  },
  fullHeight: {
    height: "100%!important",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  wrapper: {
    padding: 0,
    marginTop: "0px !important",
    height: "auto !important",
  },
  blackoutMagicWrite: {
    width: "100%",
    height: "100%",
    backgroundColor: "#fefefe",
    zIndex: 9999,
    position: "absolute",
    top: 0,
    opacity: "0.6",
  },
  imgBlackout: {
    position: "fixed",
    top: "29%",
    left: "25%",
    width: 280,
    height: 280,
  },
  cabecalho: {
    paddingBottom: 8,
  },
  content: {
    padding: "0px 8px 0px 8px",
  },
  containerPesquisas: {
    margin: 8,
    height: "100%",
  },
  itensPesquisa: {
    overflow: "auto",
    padding: "4px 12px",
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: 4,
  },
  itemContent: {
    marginBottom: 12,
  },
  listaLabel: {
    fontWeight: "bold",
  },
  formContent: {
    width: "100%",
  },
  formAcoesContent: {
    width: "100%",
    height: "100%",
  },
  formBtn: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  ajustes: {
    display: "none",
  },
  espacoConsumo: {
    margin: "8px 4px",
  },
  actions: {
    padding: "0px 8px 8px 8px",
  },
}))(AbaRDMentorIA);
