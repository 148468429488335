import React from "react";
import PropTypes from "prop-types";
import { MenuItem, Typography, ListItemText, ListItem, ListItemIcon, Divider, Link } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import "./styles.css";

function MenuItemCriarNovo({ url, intl, callBack }) {
  const funcaoCallBack = () => {
    if (callBack !== undefined) callBack(null);
  };

  return (
    <>
      <Divider style={{ marginBottom: 8 }} />
      <MenuItem className="menu" onClick={funcaoCallBack} id="menuItemCriarNovo">
        <ListItem
          disableRipple
          disableTouchRipple
          button
          component={Link}
          href={url}
          target="_blank"
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "3px",
            paddingTop: "14px",
            backgroundColor: "#f4f5f700",
            marginRight: "0px !important",
          }}
        >
          <ListItemIcon style={{ marginRight: "0px !important" }}>
            <AddCircle style={{ color: "#457AB7" }} />
          </ListItemIcon>
          <ListItemText
            style={{ marginLeft: "-17px !important", paddingLeft: 0 }}
            primary={
              <Typography
                style={{
                  color: "#457AB7",
                  fontSize: "1rem",
                  fontWeight: 400,
                  lineHeight: 1.5,
                  letterSpacing: "0.00938em",
                }}
              >
                {intl.formatMessage({ defaultMessage: "Criar novo" })}
              </Typography>
            }
          />
        </ListItem>
      </MenuItem>
    </>
  );
}

MenuItemCriarNovo.propTypes = {
  url: PropTypes.string,
  intl: PropTypes.object,
  callBack: PropTypes.func,
};

export default MenuItemCriarNovo;
