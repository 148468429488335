import React, { useEffect, useState } from "react";
import axios from "axios";
import env from "../../../../environment";

import "./assets/css/SummitBannerRD.css";

import Atom from "./assets/img/Atom.png";
import AtomoTablet from "./assets/img/Atomo_tablet.png";
import AtomoDesk from "./assets/img/Atomo_desk.png";
import Vector from "./assets/img/Vector.png";
import LogoRDSummit from "./assets/img/RDSummit-logo.png";
import Time from "./assets/img/Time.png";
import Head from "./assets/img/Head.png";
import HeadDesk from "./assets/img/Head_desk.png";
import { useAuthVerification } from "../../../../hooks";
import localStorageManager from "../../../utils/storage";
import { RD_SUMMIT_STICKBAR } from "../../../utils/storage/constantesStorage";

const TipoBannerRequest = {
  BANNER_LOGIN_SUMMIT_RD: 0,
};

const SummitBannerRD = () => {
  const [showSummitBanner, setShowSummitBanner] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const usuarioLogado = useAuthVerification();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = env.REACT_APP_BASE_URL;
        const response = await axios.get(
          `${baseUrl}/api/pipeline/appsetting/PermiteExibirBanner/${TipoBannerRequest.BANNER_LOGIN_SUMMIT_RD}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setShowSummitBanner(response.data.vizualizacaoPermitida);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    const dimmissRDSummitStickbar = localStorageManager.getItem(RD_SUMMIT_STICKBAR);
    if (!dimmissRDSummitStickbar) {
      setIsBannerOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorageManager.setItem(RD_SUMMIT_STICKBAR, true);
    window.dispatchEvent(new Event("storageChange"));
    window.dispatchEvent(new Event("changeRenderFunil"));
    setIsBannerOpen(false);
  };

  return (
    showSummitBanner && (
      <div id="bannerRD" className={`rd-summit-banner ${usuarioLogado ? "position-relative" : ""}`}>
        <div className={`rd-summit-navbar ${isBannerOpen ? "show" : ""}`}>
          <div className="rdsn_container">
            <div className="rdsn_icons">
              <img className="rdsn_image-mobile" src={Atom} alt="Imagem de atomo na cor roxa" loading="lazy" />
              <img className="rdsn_image-tablet" src={AtomoTablet} alt="Imagem de atomo na cor roxa" loading="lazy" />
              <img className="rdsn_image-desktop" src={AtomoDesk} alt="Imagem de atomo na cor roxa" loading="lazy" />
              <img className="rdsn_target" src={Vector} alt="Icone de um alvo preto" loading="lazy" />
            </div>
            <div className="rdsn_content">
              <div className="rdsn_logo">
                <img src={LogoRDSummit} alt="Logo do RD Summit" loading="lazy" />
              </div>
              <div className="rdsn_description">
                <p>
                  Participe do maior evento de marketing, vendas e ecommerce da América Latina com{" "}
                  <strong>25% de desconto</strong> em todos os ingressos.{" "}
                  <a
                    href="https://www.sympla.com.br/evento/rd-summit-2024-publico-geral/2353949?d=cliente-exact&utm_source=navbar&utm_medium=link&utm_campaign=br-even-rdsummit-navbar-conversion-clientes-teste-a-b-navbar-ticket--exact-ago_w1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rdsn_break-line"
                  >
                    <strong>
                      <u>Garanta já o seu!</u>
                    </strong>
                  </a>
                </p>
              </div>
            </div>
            <div className="rdsn_close">
              <div className="rdsn_close_icon">
                <button
                  onClick={handleClose}
                  style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}
                >
                  <img width="12px" height="12px" src={Time} alt="Icone de X para fechar" loading="lazy" />
                </button>
              </div>
              <img
                className="rdsn_image-mobile"
                src={Head}
                alt="Imagem de uma cabeça roxa com uma fechadura na parte superior"
                loading="lazy"
              />
              <img
                className="rdsn_image-desktop"
                src={HeadDesk}
                alt="Imagem de uma cabeça roxa com uma fechadura na parte superior"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SummitBannerRD;
