import React from "react";
import PropTypes from "prop-types";
import { ActionSearch } from "material-ui/svg-icons";
import injectSheet from "../../hocs/injectSheet/injectSheet";
import Flex from "../Flex";

const styles = theme => ({
  inputContainer: {
    transform: "translateX(-50%)",
    position: "relative",
    "&:hover $inputImage": {
      fill: `${theme.palette.text.darkPrimaryText} !important`,
    },
    "&:hover $inputBuscarLeads": {
      backgroundColor: theme.tabs.backgroundColor,
      color: `${theme.palette.text.darkPrimaryText} !important`,
    },
  },
  inputBuscarLeads: {
    width: 300,
    height: 40,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderColor: "rgba(0, 0, 0, 0.1)",
    color: theme.palette.text.lightSecondaryText,
    paddingLeft: 40,
    outline: "none",
    "&::placeholder": {
      color: theme.palette.icons.lightActiveIcon,
      fontSize: 14,
      fontWeight: 500,
    },
    "&:hover::placeholder": {
      color: theme.palette.text.darkPrimaryText,
    },
  },
  inputImage: {
    position: "absolute",
    bottom: 8,
    left: 6,
  },
});

function CampoBuscarLeads({ handleOnKeypressBuscarLead, theme, classes }) {
  return (
    <Flex className={classes.inputContainer}>
      <ActionSearch color={theme.palette.icons.lightActiveIcon} className={classes.inputImage} />
      <input
        type="text"
        className={classes.inputBuscarLeads}
        placeholder="Buscar Leads"
        onKeyPress={handleOnKeypressBuscarLead}
      />
    </Flex>
  );
}

CampoBuscarLeads.propTypes = {
  handleOnKeypressBuscarLead: PropTypes.func.isRequired,
  theme: PropTypes.object,
  classes: PropTypes.object,
};

export default injectSheet(styles)(CampoBuscarLeads);
