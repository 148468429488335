import "typeface-roboto/index.css";
import "react-tippy/dist/tippy.css";
import "draft-js-focus-plugin/lib/plugin.css";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "react-app-polyfill/stable";
import { BrowserRouter } from "react-router-dom";

import { PublicRouter } from "../../../publico";
import injectSheet from "../../hocs/injectSheet";
import Tema from "../Tema/Tema";
import "../../BootstrapMinificado/index.min.css";
import MenuLateral from "../../components/MenuLateral";
import isLogado from "../../utils/auth/auth";
import Navbar from "../../containers/Navbar/Navbar";
import ConnectedIntlProvider from "../../containers/ConnectedIntlProvider";
import SummitBannerRD from "../Banners/SummitRD2024/SummitBannerRD";

moment.locale("pt-BR");

const styles = {
  "@global div#page-content-wrapper": {
    overflowY: "auto !important",
    overflowX: "hidden !important",
  },
  "@global div#root, div#rsg-root": {
    fontFamily: "Roboto",
    boxSizing: "border-box",
  },
  "@global div#root *, div#root *::before, div#root *::after, div#rsg-root *, div#rsg-root *::before, div#rsg-root *::after": {
    boxSizing: "inherit",
  },
  "@global html": {
    fontSize: 16,
  },
  "@global audio::-webkit-media-controls-volume-slider": {
    display: "none",
  },
  "@global audio::-webkit-media-controls-mute-button": {
    display: "none",
  },
  "@global audio::-webkit-media-controls-panel": {
    backgroundColor: "white",
  },
  "@global .tippy-tooltip.paper-theme": {
    backgroundColor: "white !important",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important",
  },
  "@global .tippy-tooltip": {
    fontSize: "12px !important",
  },
  "@global body": {
    fontSize: "14px !important",
    lineHeight: "unset !important",
    backgroundColor: "#f2f2f2 !important",
  },
  "@global ::-webkit-scrollbar": {
    width: 12,
  },
  "@global ::-webkit-scrollbar-track": {
    backgroundColor: "#eaeaea",
    borderLeft: "1px solid #ccc",
  },
  "@global ::-webkit-scrollbar-thumb": {
    backgroundColor: "#ccc",
  },
  "@global ::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#aaa",
  },
};

const PUBLIC_BASENAME = "/public/";
const FEEDBACK_BASENAME = "/reuniao/";
const CONFERENCIA_BASENAME = "/conferencia/";

const estaNoPublic = () =>
  window.location.href.includes(PUBLIC_BASENAME) ||
  window.location.href.includes(FEEDBACK_BASENAME) ||
  window.location.href.includes(CONFERENCIA_BASENAME);

const renderRouter = (component, basename) => <BrowserRouter basename={basename}>{component}</BrowserRouter>;

const AppWrapper = ({ temRouter, children }) => {
  const discador = window.location.pathname === "/webphone/discador";
  const companion = window.location.pathname === "/magic/companion";
  const esconderComponente = companion || discador || !isLogado();

  let component = (
    <Tema>
      <>
        {temRouter &&
          !esconderComponente && (
            <ConnectedIntlProvider>
              <SummitBannerRD />
              <Navbar />
              <MenuLateral />
            </ConnectedIntlProvider>
          )}
        {children}
      </>
    </Tema>
  );
  if (!temRouter) return component;

  if (estaNoPublic()) {
    component = (
      <Tema>
        <PublicRouter />
      </Tema>
    );
  }

  return renderRouter(component, "/");
};

AppWrapper.propTypes = {
  temRouter: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

AppWrapper.defaultProps = {
  temRouter: false,
};

export default injectSheet(styles)(AppWrapper);
