import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { injectSheet } from "../../hocs";

const styles = {
  paper: {
    width: "65% !important",
    maxWidth: "none !important",
  },
};

InnerModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  actions: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.object,
};

function InnerModal({ children, title, actions, classes, open, onClose, ...restProps }) {
  return (
    <Dialog PaperProps={{ className: classes.paper }} open={open} onClose={onClose} {...restProps}>
      <DialogTitle>{title} </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}

export default injectSheet(styles)(InnerModal);
