import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import axios from "axios";
import { compose } from "recompose";
import { FlatButton, FontIcon, TextField } from "material-ui";
import { withRouter } from "react-router-dom";
import { objectKeysToCamelCase } from "../../_common/utils/string";
import { Modal, Flex, Tipografia, AjaxBlackout, ExactWebPhone } from "../../_common";
import socket, { eActions } from "../../websocket/socket";
import broadcast, { eEvents } from "../../utils/broadcast";
import AsyncSelect from "../../components/AsyncSelect";

import "./ResultadoLigacao.css";
import createSnackBar from "../../_common/utils/snackbar/createSnackbar";
import { TIPO_PLANO_SPOTTER } from "../constantes";
import { AddLogError } from "../utils/startUpApplication/analyticsProvider/errorAnalyticsService";

const stateDefault = {
  open: false,
  quantidadeEstrelasMarcadas: 0,
  step: 1,
  resultadoLigacaoHabilitado: true,
  resultadoSelecionado: 0,
  obs: "",
  motivo: "",
  resultadosLigacao: [],
  CallerId: 0,
  contadorObs: 0,
  contadorMotivo: 0,
  bloqueiaSalvar: false,
  nomeLead: null,
  openLista: false,
};

const LIMITE_CARACTERES = 3000;

class ResultadoLigacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = stateDefault;
    this.webPhoneBroadcast = ExactWebPhone().getBroadcastChannel();
  }

  componentDidMount() {
    broadcast.on(eEvents.ABRIR_MODAL_RESULTADO_LIGACAO, this.abrirModalCarregarInformacoes);
    broadcast.on(eEvents.FECHAR_MODAL_RESULTADO_LIGACAO, this.fecharModalListener);
    socket.on(eActions.WEBPHONE_COLETA_RESULTADO_LIGACAO, this.recebeDadosLigacaoSocket);

    this.webPhoneBroadcast.onmessage = ({ data }) => {
      if (data.type === "ExactWebPhone.sinalizarResultadoLigacao") {
        this.recebeDadosLigacaoWebPhone(data.payload);
      }
    };
  }

  componentWillUnmount() {
    broadcast.off(eEvents.ABRIR_MODAL_RESULTADO_LIGACAO, this.abrirModalCarregarInformacoes);
    broadcast.off(eEvents.FECHAR_MODAL_RESULTADO_LIGACAO, this.fecharModalListener);
    socket.off(eActions.WEBPHONE_COLETA_RESULTADO_LIGACAO, this.recebeDadosLigacaoSocket);
    this.webPhoneBroadcast.close();
  }

  deveAbrirResultadoLigacao = data =>
    !this.state.open && !!data.callId && this.props.usuarioLogadoId === data.usuarioId;

  recebeDadosLigacaoSocket = payload => {
    const data = objectKeysToCamelCase(payload);
    if (!this.deveAbrirResultadoLigacao(data)) return;

    const detail = {
      leadId: data.leadId,
      CallerId: data.callId,
      resultadoLigacaoHabilitado: data.resultadoLigacaoHabilitado,
      nomeLead: data.nomeLead,
      usuarioId: data.usuarioId,
    };

    this.abrirModalCarregarInformacoes({ detail });
  };

  recebeDadosLigacaoWebPhone = payload => {
    if (!this.deveAbrirResultadoLigacao(payload)) return;

    this.abrirModalCarregarInformacoes({
      detail: {
        leadId: payload.leadId,
        CallerId: payload.callId,
        resultadoLigacaoHabilitado: payload.resultadoLigacaoHabilitado,
        nomeLead: payload.nomeLead,
        usuarioId: payload.usuarioId,
      },
    });
  };

  abrirModalCarregarInformacoes = async params => {
    try {
      const resp = await axios.get("/Api/Pipeline/ResultadoLigacao/Listar?somenteAtivos=true&traduzirDescricao=true");
      const resultadosLigacao = objectKeysToCamelCase(resp.data);
      this.setState({
        resultadosLigacao,
        open: true,
        leadId: params.detail.leadId,
        CallerId: params.detail.CallerId,
        resultadoLigacaoHabilitado: params.detail.resultadoLigacaoHabilitado,
        flRegistroManual: params.detail.flRegistroManual,
        faseLeadId: params.detail.faseLeadId,
        nomeLead: params.detail.nomeLead,
      });
    } catch (ex) {
      createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Ocorreu um erro ao buscar as informações " }));
    }
  };

  buscarResultadosLigacao = async () => {
    try {
      const resp = await axios.get("/NovoDetalheLead/RetornarResultadosLigacao");
      const resultadosLigacao = objectKeysToCamelCase(resp.data);
      this.setState({ ...this.state, resultadosLigacao, open: true, openLista: true });
    } catch (ex) {
      createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Ocorreu um erro ao buscar as informações" }));
    }
  };

  retornarEstrelas = () => {
    if (!this.exibeEstrelas()) {
      return [];
    }
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <FontIcon
          key={i}
          hoverColor="#FFD700"
          onClick={this.handleClickStar}
          color={this.state.quantidadeEstrelasMarcadas > i ? "#FFD700" : "#000000"}
          title={this.props.intl.formatMessage({ defaultMessage: "Clique para selecionar a qualificação" })}
          className="material-icons star"
          index={i}
        >
          {this.state.quantidadeEstrelasMarcadas > i ? "start" : "star_border"}
        </FontIcon>
      );
    }
    return stars;
  };

  exibeEstrelas = () =>
    (this.state.step !== 1 && this.state.resultadoLigacaoHabilitado) || !this.state.resultadoLigacaoHabilitado;

  handleAvancar = () => {
    this.setState({
      ...this.state,
      step: 2,
    });
  };

  handleClickStar = event => {
    this.setState({
      ...this.state,
      quantidadeEstrelasMarcadas: Number(event.currentTarget.attributes.index.value) + 1,
    });
  };

  mostraMensagemProvedor = () => this.state.quantidadeEstrelasMarcadas > 0 && this.state.quantidadeEstrelasMarcadas < 4;

  handleFecharModal = (limparEstado = false) =>
    broadcast.dispatch(eEvents.FECHAR_MODAL_RESULTADO_LIGACAO, { limparEstado });

  fecharModalListener = ({ limparEstado }) => {
    if (limparEstado) this.setState(stateDefault);
    else this.setState({ ...this.state, open: false });
  };

  handleVoltar = () => {
    if (this.state.step === 2) {
      this.setState({
        ...this.state,
        step: 1,
      });
    }
  };

  handleChange = (campo, valor, contador) => {
    if (contador && valor.length <= LIMITE_CARACTERES)
      this.setState({ ...this.state, [campo]: valor, [contador]: valor.length });
    else if (!contador) this.setState({ ...this.state, [campo]: valor });
  };

  validarDados = validarDados => {
    if (!this.state.flRegistroManual) {
      if (!validarDados) return true;
    }

    if (this.state.resultadoSelecionado === 0 && this.state.resultadoLigacaoHabilitado) {
      createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Selecione um resultado de ligação" }));
      return false;
    }

    if (this.state.quantidadeEstrelasMarcadas === 0 && !this.state.resultadoLigacaoHabilitado) {
      createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Informe sua avaliação" }));
      return false;
    }

    return true;
  };

  salvarDados = async validarDados => {
    try {
      AjaxBlackout.Show();
      if (this.state.flRegistroManual) {
        if (validarDados) {
          if (this.validarDados(validarDados)) {
            const args = {
              observacao: this.state.obs,
              motivo: this.state.motivo,
              avaliacao: null, // o que é avaliacação? de onde vem no modal?
              estrelas: this.state.quantidadeEstrelasMarcadas,
              ligacaoId: this.state.CallerId,
              leadId: this.state.leadId,
              resultadoLigacaoId: this.state.resultadoSelecionado,
              faseLeadId: this.state.faseleadId,
            };
            await axios.post("/api/pipeline/ResultadoLigacao/Registrar", args);
            ExactWebPhone().registrarHoraUltimaAvaliacao();

            const path = this.props.location.pathname.split("/");
            const idLead = parseInt(path.at(-1), 10);

            const idLeadState = parseInt(this.state.leadId, 10);

            if (!Number.isNaN(idLeadState) && !Number.isNaN(idLead) && idLeadState === idLead) {
              document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
            } else if (
              this.state.leadId === null ||
              this.state.leadId === undefined ||
              (!Number.isNaN(idLeadState) && !Number.isNaN(idLead) && idLeadState !== idLead)
            ) {
              // #region Região criada para tentar detectar inconsistência entre o lead da rota e o lead passado como param no resultado ligação
              AddLogError({
                exception: new Error(
                  `file: ResultadoLigacao.js - SalvarDados() - Leads com ids diferentes - this.state.leadId: ${
                    this.state.leadId
                  } - idLead: ${idLead} - rota: ${this.props.location.pathname}`
                ),
              });
              // #endregion
            }
            createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Resultado salvo com sucesso" }));
            this.handleFecharModal(true);
          }
        } else {
          this.handleFecharModal(true);
        }
      } else if (this.validarDados(validarDados)) {
        this.setState({
          ...this.state,
          bloqueiaSalvar: true,
        });

        this.handleFecharModal(false);

        await axios.post("/Api/Pipeline/Ligacao/Editar", {
          Observacao: this.state.obs,
          Motivo: this.state.motivo,
          Avaliacao: this.state.resultadoSelecionado,
          Estrelas: this.state.quantidadeEstrelasMarcadas,
          LigacaoId: this.state.CallerId,
          LeadId: this.state.leadId,
        });

        ExactWebPhone().registrarHoraUltimaAvaliacao();
        const path = this.props.location.pathname.split("/");
        if (path[path - 1] === null || path[path - 1] === this.state.leadId) {
          document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));

          // #region Região criada para tentar detectar inconsistência entre o lead da rota e o lead passado como param no resultado ligação
          const idLead = parseInt(path.at(-1), 10);
          const idLeadState = parseInt(this.state.leadId, 10);

          if (
            this.state.leadId === null ||
            this.state.leadId === undefined ||
            (!Number.isNaN(idLeadState) && !Number.isNaN(idLead) && idLeadState !== idLead)
          ) {
            AddLogError({
              exception: new Error(
                `file: ResultadoLigacao.js - SalvarDados() - Leads com ids diferentes - this.state.leadId: ${
                  this.state.leadId
                } - idLead: ${idLead} / else - rota: ${this.props.location.pathname}`
              ),
            });
          }
          // #endregion
        }

        if (this.state.resultadoLigacaoHabilitado) {
          createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Resultado salvo com sucesso" }));
        }

        this.handleFecharModal(true);
      }
    } catch (err) {
      createSnackBar(this.props.intl.formatMessage({ defaultMessage: "Ocorreu um erro ao salvar os dados" }));
    } finally {
      AjaxBlackout.Hide();
    }
  };

  escolherResultadoLigacao = item => {
    this.handleChange("resultadoSelecionado", item.target.value || 0);
  };

  renderizaResultadoLigacao = () => {
    if (this.state.resultadoLigacaoHabilitado) {
      return (
        <div onClick={this.handleVoltar}>
          {this.state.nomeLead && (
            <Flex style={{ paddingTop: 12 }}>
              <Tipografia cor="darkSecondaryText">
                <FormattedMessage
                  defaultMessage="Ligação para {nomeLead}"
                  values={{ nomeLead: <b> {this.state.nomeLead} </b> }}
                />
              </Tipografia>
            </Flex>
          )}
          <Flex>
            <div className="circulo">{this.state.step === 1 ? "1" : ""}</div>
            <div className={this.state.step === 1 ? "title" : "title opacity"}>
              {this.props.intl.formatMessage({ defaultMessage: "Resultado da ligação" })}
            </div>
          </Flex>
          {this.state.step === 1 ? (
            <React.Fragment>
              <Flex>
                <div className={this.state.step === 1 ? "subTitle" : "subTitle opacity"}>
                  {this.props.intl.formatMessage({ defaultMessage: "Selecione uma opção*" })}
                </div>
              </Flex>
              <Flex style={{ marginLeft: 52 }}>
                <AsyncSelect
                  fullWidth
                  id="selectResultadoLigacao"
                  hintText={this.props.intl.formatMessage({ defaultMessage: "Resultado da ligação" })}
                  floatingLabelText={this.props.intl.formatMessage({ defaultMessage: "Resultado da ligação" })}
                  label={this.props.intl.formatMessage({ defaultMessage: "Resultado da ligação" })}
                  url="/Api/Pipeline/ResultadoLigacao/Listar?somenteativos=true&traduzirDescricao=true"
                  value={this.state.resultadoSelecionado}
                  onChange={this.escolherResultadoLigacao}
                  enableSearch
                  searchLabel={this.props.intl.formatMessage({ defaultMessage: "Pesquisar por resultado de ligação" })}
                  className="ml50"
                  criarNovo={this.props.tipoPlanoSpotter === TIPO_PLANO_SPOTTER.FULL}
                  urlCriarNovo={`/spotter/configuracoes/padroes`}
                  recarregarLista
                />
              </Flex>
              {this.state.resultadoSelecionado !== 0 ? (
                <Flex>
                  <TextField
                    rows={3}
                    multiLine
                    id="txfObservacaoes"
                    type="text"
                    className="ml50"
                    placeholder={this.props.intl.formatMessage({ defaultMessage: "Observações" })}
                    value={this.state.obs}
                    onChange={(event, value) => this.handleChange("obs", value, "contadorObs")}
                  />
                  <Flex justifyContent="flex-end" className="flexContagem">{`${
                    this.state.contadorObs
                  }/${LIMITE_CARACTERES}`}</Flex>

                  {!this.state.flRegistroManual && (
                    <FlatButton
                      id="btnProximo"
                      className="btnProximo"
                      label={this.props.intl.formatMessage({ defaultMessage: "Próximo" })}
                      default
                      primary
                      onClick={this.handleAvancar}
                    />
                  )}
                </Flex>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            !this.state.flRegistroManual && (
              <Flex className="ml50" color="#e4e4e4">
                <FontIcon
                  color="#e4e4e4"
                  title={this.props.intl.formatMessage({ defaultMessage: "Clique para selecionar a qualificação" })}
                  className="material-icons"
                >
                  done
                </FontIcon>
                &nbsp;Pronto!
              </Flex>
            )
          )}
        </div>
      );
    }
    return "";
  };

  renderizaNumeroQualidadeLigacao = () => {
    if (this.exibeEstrelas()) {
      return this.state.resultadoLigacaoHabilitado ? "2" : "1";
    }
    return "";
  };
  renderQualidadeDeLigacao = () => (
    <div>
      <Flex>
        <div className={this.exibeEstrelas() ? "circulo" : "circulo opacity"}>
          {this.renderizaNumeroQualidadeLigacao()}
        </div>
        <div className={this.exibeEstrelas() ? "title" : "title opacity"}>
          {this.props.intl.formatMessage({ defaultMessage: "Qualidade da ligação" })}
        </div>
      </Flex>
      <Flex className="ml50">{this.retornarEstrelas()}</Flex>
      {this.exibeEstrelas() ? (
        <Flex flexDirection="column">
          <Flex>
            <TextField
              rows={3}
              multiLine
              id="txfMotivoEscolha"
              visibility={this.exibeEstrelas()}
              type="text"
              className="ml50"
              placeholder={this.props.intl.formatMessage({ defaultMessage: "Descreva o motivo da sua escolha" })}
              value={this.state.motivo}
              onChange={(event, value) => this.handleChange("motivo", value, "contadorMotivo")}
            />
            <Flex justifyContent="flex-end" className="flexContagem">{`${
              this.state.contadorMotivo
            }/${LIMITE_CARACTERES}`}</Flex>
          </Flex>
          {this.mostraMensagemProvedor() ? (
            <>
              <Flex flexDirection="column">
                {this.props.intl.formatMessage(
                  {
                    defaultMessage:
                      "Você sabia que você pode trocar seu provedor se a qualidade de suas ligações não estiver positiva? <a>Clique aqui para trocar.</a>",
                  },
                  {
                    a: (...chunks) => (
                      <a href="/spotter/conta/webphone" target="_blank" rel="noopener noreferrer">
                        {chunks}
                      </a>
                    ),
                  }
                )}
              </Flex>
            </>
          ) : (
            ""
          )}
        </Flex>
      ) : (
        ""
      )}
    </div>
  );

  render() {
    const { intl } = this.props;

    return (
      <Modal
        title={intl.formatMessage({ defaultMessage: "Conte-nos como foi" })}
        open={this.state.open}
        mostraBotaoFechar={false}
        maxWidth="sm"
        fullWidth
        actions={[
          <FlatButton
            id="btnFechar"
            key="btnFechar"
            label={intl.formatMessage({ defaultMessage: "Fechar" })}
            disabled={this.state.bloqueiaSalvar}
            onClick={() => this.handleFecharModal(true)}
          />,
          <FlatButton
            id="btnEnviar"
            disabled={this.state.bloqueiaSalvar}
            key="btnEnviar"
            label={intl.formatMessage({ defaultMessage: "Enviar" })}
            primary
            onClick={() => this.salvarDados(true)}
          />,
        ]}
      >
        {this.renderizaResultadoLigacao()}
        {!this.state.flRegistroManual && this.renderQualidadeDeLigacao()}
      </Modal>
    );
  }
}

ResultadoLigacao.propTypes = {
  usuarioLogadoId: PropTypes.number,
  intl: PropTypes.object,
  location: PropTypes.object,
  tipoPlanoSpotter: PropTypes.number,
};

const mapStateToProps = state => ({ usuarioLogadoId: state.usuario.id, tipoPlanoSpotter: state.usuario.tipoPlano });

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const enhance = compose(
  injectIntl,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(ResultadoLigacao);
