import { INFOS_MENU_ROTAS } from "../../constantesMenuRotas";

const routes = {};

Object.keys(INFOS_MENU_ROTAS).forEach(routeKey => {
  const routeItem = INFOS_MENU_ROTAS[routeKey];

  routes[routeKey] = {
    route: routeItem.url,
    roles: routeItem.roles,
    claims: routeItem.claims,
    featureToggles: routeItem.featureToggles,
  };

  if (routeItem.accordionItems) {
    routeItem.accordionItems.forEach(accordionItem => {
      routes[`${accordionItem.itemAccordionKey}`] = {
        route: accordionItem.url,
        roles: accordionItem.roles,
        claims: accordionItem.claims,
        featureToggles: accordionItem.featureToggles,
        customPermission: accordionItem.customPermission,
      };
    });
  }
});

export default routes;
