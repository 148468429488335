import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

import { Loading } from "../..";
import MainRoutes from "../MainRoutes/MainRoutes";
import ConnectedIntlProvider from "../../containers/ConnectedIntlProvider";
import ResultadoLigacao from "../../ResultadoLigacao/ResultadoLigacao";
import ModalFeedbackLigacao from "../../ModalFeedbackLigacao";
import HubNotificacoes from "../../../components/HubNotificacoes/HubNotificacoes";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import AtividadeSugerirConclusao from "../../../pages/atividade/components/SugerirConclusao";
import FloatingButton from "../FloatingButton";
import { useAuthVerification, useUsuario } from "../../../hooks";
import favicon from "../../icones/favicon.ico";
import faviconExact from "../../icones/favicon-exact.ico";
import AlertaBanner from "../Banners/Alerta/AlertaBanner";
import "../../legacyStyles/ExactSales.Infra.css";
import "../../legacyStyles/graficos/graficos.css";
import "../../legacyStyles/custom/ExactModal.css";
import "../../legacyStyles/custom/ExactLayout.css";
import "../../legacyStyles/custom/ExactCustom.css";
import "../../legacyStyles/NovoLayout/simple-sidebar.css";
import "../../../utils/xhrMonkeyPatch";
import "../../../utils/xhrMonkeyPatchWootric";
import { useAppInsightsLoader } from "../../../hooks/useAppInsightsLoader";
import InlineManualScript from "../../utils/startUpApplication/inlineManual";
import localStorageManager from "../../utils/storage";
import { INFOS_USUARIO, RD_SUMMIT_STICKBAR } from "../../utils/storage/constantesStorage";
import routes from "../MainRoutes/constantes";
import useLocalStorageListener from "../../../hooks/useLocalStorageListener";
import { SITUACAO_REGISTRO } from "../../constantes";
import isLogado, { deslogar } from "../../utils/auth/auth";

function App() {
  const location = useLocation();
  const history = useHistory();
  useAppInsightsLoader();
  const estaLogado = isLogado();
  const [abrirFormulario, setAbrirFormulario] = useState(true);

  const usuarioLogado = useAuthVerification();
  const discador = window.location.pathname === "/webphone/discador";
  const baseLeads = window.location.pathname === "/spotter/base-leads/funil";
  const esconderComponente = window.location.pathname === "/magic/companion" || discador || !usuarioLogado;
  const bodyRoot = document.getElementById("body-root");
  const divRoot = document.getElementById("root");
  const usuario = localStorageManager.getItem(INFOS_USUARIO);
  const rotasSemInline = [routes.DISCADOR_WEBPHONE.route];
  const isRotaSemInline = rotasSemInline.includes(window.location.pathname);
  const dimmissRDSummitStickbar = useLocalStorageListener(RD_SUMMIT_STICKBAR);
  const { featureToggles } = useUsuario();

  useEffect(
    () => {
      const changeFavicon = href => {
        const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "icon";
        link.href = href;
        document.getElementsByTagName("head")[0].appendChild(link);
      };
      if (location.pathname.includes("Account")) {
        changeFavicon(favicon);
      } else {
        changeFavicon(faviconExact);
      }
      if (estaLogado) {
        axios.get("/Api/Pipeline/AceiteColetaDados/AbrirColetaDados").then(resp => {
          setAbrirFormulario(resp.data);
        });
      }
      const verificaSituacaoRegistro = async () => {
        try {
          const { data: situacaoRegistroUsuario } = await axios.get(
            `/Api/Pipeline/Usuario1/SituacaoRegistro?usuarioId=${usuario.id}`
          );
          if (situacaoRegistroUsuario !== SITUACAO_REGISTRO.ATIVO) {
            deslogar();
            history.push("/Account/Login");
          }
        } catch (error) {
          console.error(error);
        }
      };
      verificaSituacaoRegistro();
    },
    [location.pathname]
  );

  const addInline =
    !isRotaSemInline &&
    !usuario?.estaNoAmbienteDeDesenvolvimento &&
    usuario?.estaNoAmbienteDeDesenvolvimento !== undefined &&
    usuario?.estaNoAmbienteDeDesenvolvimento !== null;

  if (addInline) InlineManualScript(location, usuario);

  if (!discador) {
    bodyRoot.style.overflow = "hidden";
    divRoot.style.marginBottom = "72px";
  }

  const estiloCondicional = discador
    ? {}
    : {
        width: "100%",
        position: "absolute",
        height: !featureToggles.bannerLogin || dimmissRDSummitStickbar ? "100%" : "calc(100% - 64px)",
        overflowY: "auto",
        paddingBottom: 24,
        paddingLeft: 50,
        paddingTop: baseLeads ? 57 : 50,
      };

  return usuarioLogado != null ? (
    <ConnectedIntlProvider>
      <div style={{ height: "calc(100% - 56px)" }}>
        <div id="divEstiloCondicionalWrapper" style={estiloCondicional}>
          <MainRoutes abrirFormulario={abrirFormulario} />
          <AtividadeSugerirConclusao />
          {!esconderComponente && <ResultadoLigacao />}
          <ModalFeedbackLigacao />
          <ErrorBoundary>
            <HubNotificacoes />
          </ErrorBoundary>
          {usuarioLogado && !discador && <FloatingButton />}
          {usuarioLogado && <AlertaBanner />}
        </div>
      </div>
    </ConnectedIntlProvider>
  ) : (
    <Loading isLoading />
  );
}

export default App;
