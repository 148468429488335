import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "material-ui";
import { HardwareKeyboardArrowLeft, HardwareKeyboardArrowRight } from "material-ui/svg-icons";
import { Flex, Tipografia } from "../..";

import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";

const styles = {
  icone: { height: "28px !important", width: "32px !important" },
  botaoicone: { height: "38px !important", width: "34px !important", padding: "0px !important", alignSelf: "center" },
  descricaoData: { alignSelf: "center" },
};

const FiltroDataDia = ({ botoesAvancar, botoesVoltar, data, classes }) => (
  <Flex style={{ height: 30, paddingLeft: 12, width: 290 }}>
    <IconButton
      className={classes.botaoicone}
      iconStyle={styles.icone}
      onClick={botoesVoltar}
      style={{ marginRight: "auto" }}
    >
      <HardwareKeyboardArrowLeft color="dimgray" />
    </IconButton>
    <Tipografia tipo="textoSidebar" cor="darkPrimaryText" className={classes.descricaoData}>
      {data}
    </Tipografia>
    <IconButton
      className={classes.botaoicone}
      iconStyle={styles.icone}
      onClick={botoesAvancar}
      style={{ marginLeft: "auto" }}
    >
      <HardwareKeyboardArrowRight color="dimgray" />
    </IconButton>
  </Flex>
);

FiltroDataDia.propTypes = {
  botoesAvancar: PropTypes.func,
  botoesVoltar: PropTypes.func,
  data: PropTypes.string,
  classes: PropTypes.object,
};

export default injectSheet(styles)(FiltroDataDia);
