import React from "react";
import PropTypes from "prop-types";
import { withStyles, Collapse, Portal } from "@material-ui/core";

function CollapsableToolbar({ children, show, portalRef, classes, ...props }) {
  return (
    <Portal container={portalRef}>
      <Collapse in={show} timeout={500} className={classes.collapse} {...props}>
        {children}
      </Collapse>
    </Portal>
  );
}

CollapsableToolbar.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  portalRef: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles({
  collapse: {
    position: "absolute",
    backgroundColor: "#457ab7",
    top: 0,
    paddingLeft: 24,
    paddingRight: 24,
    width: "calc(100% + 36px)",
    color: "white",
  },
})(CollapsableToolbar);
