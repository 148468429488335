import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import { FontIcon } from "material-ui";

import injectSheet from "../../../_common/hocs/injectSheet/injectSheet";
import WhatsAppIcon from "../../../pages/whatsapp/WhatsAppIcon";

const styles = {
  labelStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 32,
    height: 32,
    borderRadius: 100,
    border: "1px solid #797979",
    backgroundColor: ({ checked }) => (checked ? "#797979" : "transparent"),
    cursor: "pointer",
  },
  checkboxStyle: { display: "none" },
};

const IconeCheck = ({ id, icone, tooltip, checked, handleOnClick, classes }) => (
  <Tooltip title={tooltip} position="bottom" duration={0} interactive>
    <label htmlFor={id} className={classes.labelStyle}>
      <input type="checkbox" id={id} onChange={handleOnClick} checked={checked} className={classes.checkboxStyle} />
      {icone === "whatsapp" ? (
        <WhatsAppIcon color={checked ? "#ffffff" : "#797979"} size={18} />
      ) : (
        <FontIcon className="material-icons" color={checked ? "#ffffff" : "#797979"} style={{ fontSize: 19.19 }}>
          {icone}
        </FontIcon>
      )}
    </label>
  </Tooltip>
);

IconeCheck.propTypes = {
  /** O id do input gerado pelo componente. */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** Icone retirado do [Material](https://material.io/icons/). */
  icone: PropTypes.string.isRequired,
  /** Tooltip do icone. */
  tooltip: PropTypes.string,
  /** Indica se o icone vai estar checado ou não. */
  checked: PropTypes.bool.isRequired,
  /** Função chamada no evento onClick do icone.
   * @param {object} Event
   */
  handleOnClick: PropTypes.func,
  /** @ignore */
  classes: PropTypes.object,
};

export default injectSheet(styles)(IconeCheck);
