import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { DialogTitle, withStyles, IconButton } from "@material-ui/core";

DialogTitleClose.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  classes: PropTypes.object,
};

function DialogTitleClose({ onClose, children, classes, ...props }) {
  return (
    <DialogTitle {...props}>
      {children}
      {onClose && (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

export default withStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: 12,
    top: 14,
    color: theme.palette.grey[500],
  },
}))(DialogTitleClose);
