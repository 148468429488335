/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import env from "../../src/../../../environment";

import "./AlertaBanner.css";

const AlertaBanner = () => {
  const intl = useIntl();

  const [mensagemAlerta, setMensagemAlerta] = useState({ nomeCookie: "", mensagem: "" });

  function fecharMensagemAlerta() {
    const divAlerta = document.getElementById("divMensagemAlerta");
    if (divAlerta) {
      divAlerta.style.display = "none";
      handleCookie(mensagemAlerta?.nomeCookie, true);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = env.REACT_APP_BASE_URL;
        const response = await axios.get(`${baseUrl}/api/pipeline/Appsetting/AlertaMensagemBanner`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setMensagemAlerta(() => ({ nomeCookie: response.data.nomeCookie, mensagem: response.data.mensagem }));
        handleCookie(response.data.nomeCookie, false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    mostrarMensagemAlerta(mensagemAlerta?.nomeCookie) &&
    mensagemAlerta?.mensagem?.trim()?.length > 0 && (
      <div id="divMensagemAlerta" style={{ display: "block" }} className="divMensagemAlerta">
        <div className="divMensagemAlerta-fechar">
          <a href="#" onClick={() => fecharMensagemAlerta()}>
            X
          </a>
        </div>
        <div className="divMensagemAlerta-texto">
          <strong>{intl.formatMessage({ defaultMessage: "ATENÇÃO:" })}</strong>
          <span id="spamMensagemAlertaTexto">{mensagemAlerta.mensagem}</span>
        </div>
      </div>
    )
  );
};

function handleCookie(nomeCookie, fecharMensagem) {
  const pathCookie = "/";
  const cookieValue = document.cookie.split("; ").find(row => row.startsWith(`${nomeCookie}=`));
  const usuarioJaFechouBannerMensagemAlerta = cookieValue && cookieValue.split("=")[1] === "false";

  if (nomeCookie == null || usuarioJaFechouBannerMensagemAlerta) return;

  if (fecharMensagem && cookieValue) {
    document.cookie = `${nomeCookie}=false; expires=${new Date(
      new Date().getTime() + 5 * 30 * 24 * 60 * 60 * 1000
    ).toString()}; path=${pathCookie}`;
  } else {
    document.cookie = `${nomeCookie}=true; expires=${new Date(
      new Date().getTime() + 5 * 30 * 24 * 60 * 60 * 1000
    ).toString()}; path=${pathCookie}`;
  }
}

function mostrarMensagemAlerta(nomeCookie) {
  if (nomeCookie == null) return false;
  const cookieValue = document.cookie.split("; ").find(row => row.startsWith(`${nomeCookie}=`));
  const usuarioJaFechouBannerMensagemAlerta = cookieValue && cookieValue.split("=")[1] === "false";
  if (usuarioJaFechouBannerMensagemAlerta) {
    return false;
  }
  return true;
}

AlertaBanner.propTypes = {};

export default AlertaBanner;
