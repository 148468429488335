import React from "react";
import PropTypes from "prop-types";

import injectSheet from "../../../hocs/injectSheet";
import Divider from "../../Divider";

const styles = {
  root: {
    height: "16px !important",
    margin: "0 4px",
  },
};

const ActionsEditorTextoDivider = ({ classes }) => <Divider className={classes.root} />;
ActionsEditorTextoDivider.propTypes = {
  classes: PropTypes.object,
};

export default injectSheet(styles)(ActionsEditorTextoDivider);
