import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import injectSheet from "../../hocs/injectSheet";
import EditorTextoImage from "../EditorTextoImage";

const styles = {
  editorTextoImage: {
    whiteSpace: "initial",
    WebkitUserDrag: "none",
  },
};

class EditorTextoMedia extends React.PureComponent {
  state = {
    tamanhoImagemSetado: false,
  };

  componentDidMount = () => {
    if (!this.state.tamanhoImagemSetado) {
      this.setState({ tamanhoImagemSetado: true });
    }
  };

  render = () => {
    const { contentState, block, classes, entity, className, ...otherProps } = this.props;
    const blockEntityId = block && block.getEntityAt(0);
    if (!blockEntityId && !entity) {
      return null;
    }
    const entityNormalized = entity || contentState.getEntity(blockEntityId);
    const data = entityNormalized.data || entityNormalized.getData();
    const type = entityNormalized.type || entityNormalized.getType();

    const {
      blockProps,
      customStyleMap,
      customStyleFn,
      decorator,
      forceSelection,
      offsetKey,
      selection,
      tree,
      sheet,
      theme,
      blockStyleFn,
      ...elementProps
    } = otherProps;

    switch (type.toLowerCase()) {
      case "image": {
        const { height, width, attributes } = data;
        const { style, class: classAtt, ...restAtt } = attributes;
        let heightNormalized;
        let widthNormalized;

        if (this.state.tamanhoImagemSetado) {
          heightNormalized = elementProps.style.height;
          widthNormalized = elementProps.style.width;
        } else {
          const heightToBeNormalized = height || attributes.height;
          const widthToBeNormalized = width || attributes.width;

          heightNormalized = heightToBeNormalized ? `${heightToBeNormalized}px` : undefined;
          widthNormalized = widthToBeNormalized ? `${widthToBeNormalized}px` : undefined;
        }

        const styleNormalized = {
          ...style,
          ...elementProps.style,
          height: heightNormalized,
          width: widthNormalized,
        };

        return (
          <EditorTextoImage
            {...restAtt}
            {...elementProps}
            height={heightNormalized}
            width={widthNormalized}
            style={styleNormalized}
            className={cn(className, classAtt, classes.editorTextoImage)}
          />
        );
      }
      default:
        return null;
    }
  };
}

EditorTextoMedia.propTypes = {
  contentState: PropTypes.object,
  block: PropTypes.object,
  classes: PropTypes.object,
  entity: PropTypes.object,
  className: PropTypes.string,
};

export default injectSheet(styles)(EditorTextoMedia);
