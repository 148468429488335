import React, { Component } from "react";
import { AtomicBlockUtils, RichUtils, EditorState, Modifier, SelectionState } from "draft-js";
import PropTypes from "prop-types";
import { getSelectionEntity, getEntityRange } from "draftjs-utils";
import {
  ImageImage,
  EditorFormatSize,
  EditorFormatBold,
  EditorFormatItalic,
  EditorFormatUnderlined,
  EditorFormatListBulleted,
  EditorFormatListNumbered,
  ContentLink,
} from "material-ui/svg-icons";
import pipe from "pipe-now";
import { injectIntl } from "react-intl";

import { decorators, styleMap } from "../../../utils/editorTexto/editorTexto";
import { validarHiperLink } from "../../../utils/string/string";
import ActionsEditorTexto from "./ActionsEditorTexto.jsx";
import ActionsEditorTextoButton from "./Button";
import ActionsEditorTextoDivider from "./Divider";

class ActionsEditorTextoContainer extends Component {
  state = {
    openModalLink: false,
    urlValue: undefined,
    anchorElPopoverTamanhoFonte: null,
  };

  onClickTamanhoFonte = fontSize => {
    const { editorState } = this.props;
    const selection = editorState.getSelection();

    const nextContentState = Object.keys(styleMap).reduce(
      (contentState, style) => Modifier.removeInlineStyle(contentState, selection, style),
      editorState.getCurrentContent()
    );
    let nextEditorState = EditorState.push(editorState, nextContentState, "change-inline-style");
    const currentStyle = editorState.getCurrentInlineStyle();

    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce(
        (state, color) => RichUtils.toggleInlineStyle(state, color),
        nextEditorState
      );
    }

    if (!currentStyle.has(fontSize)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, fontSize);
    }

    this.props.onChange(nextEditorState);
    this.handleClosePopoverTamanhoFonte();
  };

  onBoldClick = () => {
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.editorState, "BOLD"));
  };

  onItalicClick = () => {
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.editorState, "ITALIC"));
  };

  onUnderlineClick = () => {
    this.props.onChange(RichUtils.toggleInlineStyle(this.props.editorState, "UNDERLINE"));
  };

  onUnorderedListClick = () => {
    this.props.onChange(RichUtils.toggleBlockType(this.props.editorState, "unordered-list-item"));
  };

  onOrderedListClick = () => {
    this.props.onChange(RichUtils.toggleBlockType(this.props.editorState, "ordered-list-item"));
  };

  onImageClick = () => {
    // Aguardando definição do Pastel sobre como será solicitada a url ao usuario.
    const url = prompt(this.props.intl.formatMessage({ defaultMessage: "Digite uma URL" })); // eslint-disable-line no-alert

    if (!url) {
      return;
    }
    const contentState = this.props.editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { attributes: { src: url } });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = pipe(
      this.props.editorState,
      editorState => AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " "),
      editorState => EditorState.forceSelection(editorState, editorState.getCurrentContent().getSelectionAfter())
    );

    this.props.onChange(newEditorState);
  };

  onLinkClick = () => {
    this.handleModalLinkOpen();
  };

  onChangeTextoExbido = event => {
    this.setState({ textoSerExibido: event.target.value });
  };

  onChangeLinkPara = event => {
    this.setState({ urlValue: event.target.value });
  };

  getListaBotoes = components => {
    const children = React.Children.toArray(this.props.children);
    const listaBotoesEditor = children.reduce((acc, child) => {
      if (child.props.ordem) {
        return [...acc.slice(0, child.props.ordem), child, ...acc.slice(child.props.ordem)];
      }

      return [...acc, child];
    }, components);

    return listaBotoesEditor;
  };

  handleOpenPopoverTamanhoFonte = event => {
    this.setState({
      anchorElPopoverTamanhoFonte: event.currentTarget,
    });
  };

  handleClosePopoverTamanhoFonte = () => {
    this.setState({
      anchorElPopoverTamanhoFonte: null,
    });
  };

  handleModalLinkOpen = () => {
    const selectionState = this.props.editorState.getSelection();
    const currentContent = this.props.editorState.getCurrentContent();
    const anchorKey = selectionState.getAnchorKey();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const textoSelecionado = currentContentBlock.getText().slice(start, end);

    let newContentState = currentContent;
    let removeuLink = false;

    const entityKey = getSelectionEntity(this.props.editorState);

    if (entityKey) {
      const entity = currentContent.getEntity(entityKey);

      if (entity.getType() === "LINK") {
        const { start: anchorOffset, end: focusOffset } = getEntityRange(this.props.editorState, entityKey);
        const selection = SelectionState.createEmpty(anchorKey).merge({
          anchorOffset,
          focusOffset,
        });
        newContentState = Modifier.applyEntity(newContentState, selection, null);
        removeuLink = true;
      }
    }

    this.props.onChange(EditorState.push(this.props.editorState, newContentState, "apply-entity"));
    if (!removeuLink) {
      this.setState({
        openModalLink: true,
        urlValue: validarHiperLink(textoSelecionado) ? textoSelecionado : "",
        textoSerExibido: textoSelecionado,
        selectionState,
      });
    }
  };

  handleModalLinkCancel = () => {
    this.setState({ openModalLink: false });
  };

  handleModalLinkOK = async () => {
    const url = this.state.urlValue.startsWith("http") ? this.state.urlValue : `http://${this.state.urlValue}`;
    const contentState = this.props.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", {
      attributes: { href: url, target: "_blank" },
    });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateReplaced = Modifier.replaceText(
      contentStateWithEntity,
      this.state.selectionState,
      this.state.textoSerExibido,
      undefined,
      entityKey
    );
    const editorState = EditorState.createWithContent(contentStateReplaced, decorators);

    this.props.onChange(editorState);
    this.handleModalLinkCancel();
  };

  definirBotaoComoClicado = estiloDoBotao =>
    this.props.tipo === "template" ? this.props.inlineStyleButtons.includes(estiloDoBotao) : false;

  render = () => {
    const { intl } = this.props;

    const components = [
      <ActionsEditorTextoButton
        key="botaoInserirImagem"
        IconComponent={ImageImage}
        onClick={this.onImageClick}
        tooltip={intl.formatMessage({ defaultMessage: "Inserir imagem" })}
        disabled={this.props.bloquearBotoes}
      />,
      <ActionsEditorTextoButton
        key="botaoInserirLink"
        active={this.props.activeButtons.link}
        IconComponent={ContentLink}
        onClick={this.onLinkClick}
        tooltip={intl.formatMessage({ defaultMessage: "Inserir link" })}
        disabled={this.props.bloquearBotoes}
      />,
      <ActionsEditorTextoDivider key="dividerBloco1" />,
      <ActionsEditorTextoButton
        key="botaoTamanhoFonte"
        autoFocusEditor
        IconComponent={EditorFormatSize}
        onClick={this.handleOpenPopoverTamanhoFonte}
        tooltip={intl.formatMessage({ defaultMessage: "Tamanho" })}
        disabled={this.props.bloquearBotoes}
      />,
      <ActionsEditorTextoButton
        key="botaoNegrito"
        autoFocusEditor
        IconComponent={EditorFormatBold}
        onClick={this.onBoldClick}
        tooltip={intl.formatMessage({ defaultMessage: "Negrito" })}
        estaClicado={this.definirBotaoComoClicado("BOLD")}
        disabled={this.props.bloquearBotoes}
      />,
      <ActionsEditorTextoButton
        key="botaoItalico"
        autoFocusEditor
        IconComponent={EditorFormatItalic}
        onClick={this.onItalicClick}
        tooltip={intl.formatMessage({ defaultMessage: "Itálico" })}
        estaClicado={this.definirBotaoComoClicado("ITALIC")}
        disabled={this.props.bloquearBotoes}
      />,
      <ActionsEditorTextoButton
        key="botaoSublinhar"
        autoFocusEditor
        IconComponent={EditorFormatUnderlined}
        onClick={this.onUnderlineClick}
        tooltip={intl.formatMessage({ defaultMessage: "Sublinhar" })}
        estaClicado={this.definirBotaoComoClicado("UNDERLINE")}
        disabled={this.props.bloquearBotoes}
      />,
      <ActionsEditorTextoDivider key="dividerBloco2" />,
      <ActionsEditorTextoButton
        key="botaoListaMarcadores"
        autoFocusEditor
        IconComponent={EditorFormatListBulleted}
        onClick={this.onUnorderedListClick}
        tooltip={intl.formatMessage({ defaultMessage: "Lista com marcadores" })}
        disabled={this.props.bloquearBotoes}
      />,
      <ActionsEditorTextoButton
        key="botaoListaNumerada"
        autoFocusEditor
        IconComponent={EditorFormatListNumbered}
        onClick={this.onOrderedListClick}
        tooltip={intl.formatMessage({ defaultMessage: "Lista numerada" })}
        disabled={this.props.bloquearBotoes}
      />,
    ];

    const listaBotoesEditor = this.getListaBotoes(components);

    return [
      <ActionsEditorTexto
        key="actionsEditorTexto"
        openModalLink={this.state.openModalLink}
        handleModalLinkCancel={this.handleModalLinkCancel}
        handleModalLinkOK={this.handleModalLinkOK}
        urlValue={this.state.urlValue}
        textoSerExibido={this.state.textoSerExibido}
        onChangeTextoExbido={this.onChangeTextoExbido}
        onChangeLinkPara={this.onChangeLinkPara}
        onClickTamanhoFonte={this.onClickTamanhoFonte}
        anchorElPopoverTamanhoFonte={this.state.anchorElPopoverTamanhoFonte}
        handleClosePopoverTamanhoFonte={this.handleClosePopoverTamanhoFonte}
      >
        {listaBotoesEditor}
      </ActionsEditorTexto>,
    ];
  };
}

ActionsEditorTextoContainer.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  activeButtons: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  inlineStyleButtons: PropTypes.array,
  tipo: PropTypes.string,
  bloquearBotoes: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(ActionsEditorTextoContainer);
