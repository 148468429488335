import React from "react";
import { Checkbox, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

CheckField.propTypes = {
  children: PropTypes.func.isRequired,
  initialChecked: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoSelect: PropTypes.bool,
  checkboxProps: PropTypes.object,
  onCheck: PropTypes.func,
};

CheckField.defaultProps = {
  initialChecked: false,
  autoFocus: false,
  autoSelect: false,
  checkboxProps: {
    color: "primary",
  },
};

function CheckField({ children, onCheck, initialChecked, classes, checkboxProps, autoFocus, autoSelect, ...props }) {
  const [isChecked, setIsChecked] = React.useState(initialChecked);
  const inputRef = React.useRef();
  const didFirstRender = React.useRef();

  React.useEffect(
    () => {
      if (!didFirstRender?.current) {
        return;
      }

      if (onCheck && typeof onCheck === "function") {
        onCheck(isChecked);
      }

      if (isChecked && inputRef?.current) {
        if (autoFocus) inputRef.current.focus();
        if (autoSelect) inputRef.current.select();
      }
    },
    [isChecked]
  );

  React.useEffect(() => {
    didFirstRender.current = true;
  }, []);

  return (
    <div className={classes.wrapper} {...props}>
      <Checkbox checked={isChecked} onChange={(evt, checked) => setIsChecked(checked)} {...checkboxProps} />

      {children({ isChecked, inputRef })}
    </div>
  );
}

export default withStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "baseline",
  },
}))(CheckField);
