import "draft-js/dist/Draft.css";
import React from "react";
import Editor, { composeDecorators } from "draft-js-plugins-editor";
import createFocusPlugin from "draft-js-focus-plugin";
import createResizeablePlugin from "draft-js-resizeable-plugin";
import createImagePlugin from "draft-js-image-plugin";
import PropTypes from "prop-types";
import cn from "classnames";

import { Flex } from "../..";
import injectSheet from "../../hocs/injectSheet";
import { decoratorArray, styleMap } from "../../utils/editorTexto/editorTexto";
import EditorTextoMedia from "../EditorTextoMedia";

const styles = theme => ({
  rootEditor: {
    overflow: "auto",
    "& .DraftEditor-root": {
      flex: "auto",
      cursor: ({ readOnly }) => (readOnly ? "not-allowed" : "text"),
      padding: 4,
      "& .draftJsFocusPlugin__focused__3Mksn": {
        cursor: "default",
        borderRadius: 2,
        boxShadow: "0 0 0 3px #0096fd",
      },
      "& .draftJsFocusPlugin__unfocused__1Wvrs:hover": {
        cursor: "default",
        borderRadius: 2,
        boxShadow: "0 0 0 3px #82ccff",
      },
      "& .DraftEditor-editorContainer": {
        height: "100%",
      },
    },
  },
  icone: {
    width: "20px !important",
    height: "20px !important",
    cursor: "pointer",
    fill: `${theme.palette.icons.darkInactiveIcon} !important`,
    "&:hover": {
      fill: `${theme.palette.icons.darkActiveIcon} !important`,
    },
  },
  botaoEnviar: {
    marginRight: 8,
  },
  hidePlaceholder: {
    "& .public-DraftEditorPlaceholder-root": {
      display: "none",
    },
  },
  "@global .DraftEditor-editorContainer figure": {
    display: "inline-block",
  },
});

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin({
  horizontal: "absolute",
  vertical: "absolute",
});
const decorator = composeDecorators(focusPlugin.decorator, resizeablePlugin.decorator);
const imagePlugin = createImagePlugin({ decorator, imageComponent: EditorTextoMedia });

const plugins = [focusPlugin, resizeablePlugin, imagePlugin];

class EditorTexto extends React.Component {
  static propTypes = {
    /**
     * Evento onchange do editor
     * @param {EditorState} editorState o editorState alterado
     */
    onChange: PropTypes.func.isRequired,
    /** O editorState usado no editor */
    editorState: PropTypes.object.isRequired,
    /**
     * Função que renderiza html de acordo com o tipo de bloco. [Mais informações.](https://draftjs.org/docs/advanced-topics-block-components.html)
     * @param {ContentBlock} contentBlock [ContentBlock](https://draftjs.org/docs/api-reference-content-block.html#content) contendo as informações do bloco a ser renderizado
     */
    mediaBlockRenderer: PropTypes.func.isRequired,
    /**
     * Função para gerenciar os atalhos do teclado. [Mais informações.](https://draftjs.org/docs/advanced-topics-key-bindings.html)
     * @param {SyntheticKeyboardEvent} e Descreve a interação do usuário com o teclado.
     * @returns {string} Retorna `handled` se o comando foi cuidado pela função e nenhuma outra ação
     * é requerida ou `not-handled` caso se deseje executar o comportamento padrão do editor.
     */
    handleAtalhosTeclado: PropTypes.func.isRequired,
    /**
     * Função `onblur` do editor.
     * @param {SyntheticFocusEvent} e Descreve a interação do usuário com o mouse.
     */
    handleOnBlur: PropTypes.func.isRequired,
    /** Classname a ser aplicado no root do componente. */
    className: PropTypes.string,
    style: PropTypes.object,
    /** Informa se o foco deve ser setado ao abrir o editor ou não. */
    /** @ignore */
    sheet: PropTypes.object,
    /** @ignore */
    theme: PropTypes.object,
    /** @ignore */
    setFocus: PropTypes.bool,
    /** @ignore */
    classes: PropTypes.object,
  };

  componentDidMount = () => {
    if (this.props.setFocus) {
      setTimeout(this.focus, 200);
    }
  };

  focus = () => this.editor.focus();

  verificaNecessidadeEsconderPlaceholder = () => {
    const contentState = this.props.editorState.getCurrentContent();
    const hasStyle =
      contentState
        .getBlockMap()
        .first()
        .getType() !== "unstyled";

    return !contentState.hasText() && hasStyle;
  };

  render() {
    // Esconde o placeholder quando a lista não tem texto
    // e começa com uma estilização em bloco
    const hidePlaceholder = this.verificaNecessidadeEsconderPlaceholder();

    const {
      classes,
      mediaBlockRenderer,
      handleAtalhosTeclado,
      sheet,
      theme,
      style,
      className,
      handleOnBlur,
      ...rest
    } = this.props;

    return (
      <Flex
        style={style}
        id="campoMensagem"
        className={cn(classes.rootEditor, { [classes.hidePlaceholder]: hidePlaceholder }, className)}
        onClick={this.focus}
      >
        <Editor
          {...rest}
          blockRendererFn={mediaBlockRenderer}
          handleKeyCommand={handleAtalhosTeclado}
          plugins={plugins}
          decorators={decoratorArray}
          onBlur={event => handleOnBlur(event, this.focus)}
          stripPastedStyles
          ref={editor => {
            this.editor = editor;
          }}
          customStyleMap={styleMap}
        />
      </Flex>
    );
  }
}
export { EditorTexto as EditorTextoPure };
export default injectSheet(styles)(EditorTexto);
